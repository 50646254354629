import React from "react";
import { Routes, Route } from "react-router-dom";

import config from "../../Config";

import PrivateRoute from "./PrivateRoute";

/* For Mobile apps */
import AppStudentProfileEdit from "../Mobile/AppStudentProfileEdit";
import AppStudentProfileDetails from "../Mobile/AppStudentProfileDetails";
import AppStaffProfileEdit from "../Mobile/AppStaffProfileEdit";
import AppStaffProfileDetails from "../Mobile/AppStaffProfileDetails";
import Success from "../Mobile/Success";
import Failed from "../Mobile/Failed";

/* open Route Components */
import Login from "../authentication/Login";
import ForgotPassword from "../authentication/ForgotPassword";
import ResetPassword from "../authentication/ResetPassword";
import ForgotPasswordVerification from "../authentication/ForgotPasswordVerification";
import LoginDashboard from "../authentication/LoginDashboard";

/* Auth Required Private Route Components */
import Dashboard from "../dashboard/Dashboard";
import AppPaymentFailed from "../dashboard/AppPaymentFailed";
import AppPaymentSuccess from "../dashboard/AppPaymentSuccess";

import PaymentSuccess from "../fees/PaymentSuccess";
import PaymentFailed from "../fees/PaymentFailed";

import NoticeBoard from "../noticeBoard/NoticeBoard";
import CreateNoticeBoard from "../noticeBoard/CreateNoticeBoard";
import EditNoticeBoard from "../noticeBoard/EditNoticeBoard";
import NoticeBoardComment from "../noticeBoard/NoticeBoardComment";

import HomeWork from "../homeWork/HomeWork";
import ApprovalHomeWork from "../homeWork/ApprovalHomeWork";
import CreateHomeWork from "../homeWork/CreateHomeWork";
import CreateHomeWorkBySubject from "../homeWork/CreateHomeWorkBySubject";
import EditHomeWork from "../homeWork/EditHomeWork";
import SubmittedHomeWork from "../homeWork/SubmittedHomeWork";
import SubmitHomeWork from "../homeWork/SubmitHomeWork";

import ClassDiary from "../classDiary/ClassDiary";
import ApprovalClassDiary from "../classDiary/ApprovalClassDiary";
import CreateClassDiary from "../classDiary/CreateClassDiary";
import CreateClassDiaryBySubject from "../classDiary/CreateClassDiaryBySubject";
import EditClassDiary from "../classDiary/EditClassDiary";

import LiveClass from "../liveClass/LiveClass";
import CreateLiveClass from "../liveClass/CreateLiveClass";

import AttendanceRegister from "../attendance/AttendanceRegister";
import AttendanceRegisterList from "../attendance/AttendanceRegisterList";
import AttendanceInformationList from "../attendance/AttendanceInformationList";
import AttendanceInformation from "../attendance/AttendanceInformation";
import StaffMyAttendance from "../attendance/StaffMyAttendance";
import StudentMyAttendance from "../attendance/StudentMyAttendance";

import TabulationWrittenOral from "../examination/TabulationWrittenOral";
import TabulationActivities from "../examination/TabulationActivities";
import TabulationSummative from "../examination/TabulationSummative";
import TabulationFormative from "../examination/TabulationFormative";
import TabulationNineTen from "../examination/TabulationNineTen";
import TabulationElevenTwelve from "../examination/TabulationElevenTwelve";
import AcademicReport from "../examination/AcademicReport";
import AcademicReportStudent from "../examination/AcademicReportStudent";
import ResultRemarks from "../examination/ResultRemarks";

import Fees from "../fees";

import StudentProfile from "../profile/StudentProfile";
import StudentProfileEdit from "../profile/StudentProfileEdit";
import StaffProfile from "../profile/StaffProfile";
import StaffProfileEdit from "../profile/StaffProfileEdit";
import StaffStudentProfile from "../profile/StaffStudentProfile";
import StudentList from "../profile/StudentList";
import StudentDetails from "../profile/StudentDetails";

import Almanac from "../Almanac";
import TimeTable from "../TimeTable";
import AcademicCalendar from "../AcademicCalendar";

import ContactUs from "../ContactUs";
import HolidayList from "../HolidayList";

/* Other Common Route Components */
import Logout from "../authentication/Logout";
import Maintenance from "./Maintenance";
//import NotFound from "./NotFound";
import NoPermission from "./NoPermission";
import AdminLayout from "./AdminLayout";
import FrontLayout from "./FrontLayout";
import MobileLayout from "./MobileLayout";
import ErrorLayout from "./ErrorLayout";
import LoginMobile from "../Mobile/LoginMobile";
import TabulationLPCD from "../examination/TabulationLPCD";
import TabulationBCO from "../examination/TabulationBCO";
import TabulationDPLS from "../examination/TabulationDPLS";
import MobileDashboard from "../Mobile/dashboard/Dashboard";
import LoginMobileDashboard from "../Mobile/LogIn/LoginMobileDashboard";
import ParentsPicture from "../parents/HomeWork";
import StaffStudentProfilePhotoEdit from "../profile/StaffStudentProfilePhotoEdit";
import CreateHomeWork111 from "../homeWork/CreateHomeWork copy";

function MainRoutes ()
{
  if ( config.IS_MAINTENANCE_MODE )
  {
    return (
      <Routes>
        <Route path="*" element={ <Maintenance /> } />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={ <MobileLayout /> }>

        <Route path="/mobile-student-profile-edit/:token/:dbtoken/:sid" element={ <AppStudentProfileEdit /> } />
        <Route path="/mobile-student-profile-details/:token/:dbtoken/:sid" element={ <AppStudentProfileDetails /> } />

        <Route path="/mobile-staff-profile-edit/:token/:dbtoken/:sid" element={ <AppStaffProfileEdit /> } />
        <Route path="/mobile-staff-profile-details/:token/:dbtoken/:sid" element={ <AppStaffProfileDetails /> } />

        <Route path="/app-login-mobile-dashboard/:resToken/:dbToken" element={ <LoginMobileDashboard /> } />
        <Route path="/app-login-mobile-dashboard/:resToken/:dbToken/:redirect" element={ <LoginMobileDashboard /> } />
        <Route path="/mobile-dashboard" element={ <MobileDashboard /> } />

        <Route path="/mobile-tabulation-eleven-twelve" element={ <TabulationElevenTwelve /> } />
        <Route path="/mobile-tabulation-nine-ten" element={ <TabulationNineTen /> } />
        <Route path="/mobile-tabulation-summative" element={ <TabulationSummative /> } />
        <Route path="/mobile-tabulation-formative" element={ <TabulationFormative /> } />
        <Route path="/mobile-lpcd" element={ <TabulationLPCD /> } />
        <Route path="/mobile-bco" element={ <TabulationBCO /> } />
        <Route path="/mobile-dpls" element={ <TabulationDPLS /> } />

        <Route path="/mobile-tabulation-activities" element={ <TabulationActivities /> } />
        <Route path="/mobile-tabulation-written-oral" element={ <TabulationWrittenOral /> } />


        <Route path="/success" element={ <Success /> } />
        <Route path="/failed" element={ <Failed /> } />

        <Route path="/login-mobile/:token/:dbtoken/:userType" element={ <LoginMobile /> } />
        <Route path="/login-dashboard" element={ <LoginDashboard /> } />

      </Route>

      <Route element={ <FrontLayout /> }>
        <Route path="/" element={ <Login /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/logout" element={ <Logout /> } />
        <Route path="/forgot-password" element={ <ForgotPassword /> } />
        <Route path="/forgot-password-verification" element={ <ForgotPasswordVerification /> } />
        <Route path="/reset-password" element={ <ResetPassword /> } />
      </Route>

      <Route element={ <AdminLayout /> }>

        <Route path="/image-crop" element={ <ParentsPicture /> } />

        <Route element={ <PrivateRoute /> }>
          <Route path="/dashboard" element={ <Dashboard /> } />
        </Route>
        <Route element={ <PrivateRoute /> }>
          <Route path="/app-payment-success" element={ <AppPaymentSuccess /> } />
        </Route>
        <Route element={ <PrivateRoute /> }>
          <Route path="/app-payment-failed" element={ <AppPaymentFailed /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "nb" } /> }>
          <Route path="/notice-board" element={ <NoticeBoard /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "nb" } role={ "staff" } /> }>
          <Route path="/create-notice-board" element={ <CreateNoticeBoard /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "nb" } role={ "staff" } /> }>
          <Route path="/edit-notice-board" element={ <EditNoticeBoard /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "nb" } /> }>
          <Route path="/notice-board-comment/:nid" element={ <NoticeBoardComment /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "hw" } /> }>
          <Route path="/home-work" element={ <HomeWork /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } role={ "staff" } /> }>
          <Route path="/create-home-work" element={ <CreateHomeWork /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } role={ "staff" } /> }>





          <Route path="/create-home-work-by-subject" element={ <CreateHomeWorkBySubject /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } role={ "staff" } /> }>
          <Route path="/edit-home-work" element={ <EditHomeWork /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } role={ "staff" } /> }>
          <Route path="/approval-home-work" element={ <ApprovalHomeWork /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } /> }>
          <Route path="/submitted-home-work" element={ <SubmittedHomeWork /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "hw" } /> }>
          <Route path="/submit-home-work" element={ <SubmitHomeWork /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "cd" } /> }>
          <Route path="/class-diary" element={ <ClassDiary /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "cd" } role={ "staff" } /> }>
          <Route path="/create-class-diary" element={ <CreateClassDiary /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "cd" } role={ "staff" } /> }>
          <Route path="/create-class-diary-by-subject" element={ <CreateClassDiaryBySubject /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "cd" } role={ "staff" } /> }>
          <Route path="/edit-class-diary" element={ <EditClassDiary /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "cd" } role={ "staff" } /> }>
          <Route path="/approval-class-diary" element={ <ApprovalClassDiary /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "lc" } /> }>
          <Route path="/live-class" element={ <LiveClass /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "lc" } role={ "staff" } /> }>
          <Route path="/create-live-class" element={ <CreateLiveClass /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "att" } role={ "staff" } /> }>
          <Route path="/attendance-register" element={ <AttendanceRegister /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "att" } role={ "staff" } /> }>
          <Route path="/attendance-register-list" element={ <AttendanceRegisterList /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "att" } role={ "staff" } /> }>
          <Route path="/attendance-information" element={ <AttendanceInformation /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "att" } role={ "staff" } /> }>
          <Route path="/attendance-informatin-list" element={ <AttendanceInformationList /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "att" } role={ "staff" } /> }>
          <Route path="/staff-my-attendance" element={ <StaffMyAttendance /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "att" } /> }>
          <Route path="/student-my-attendance" element={ <StudentMyAttendance /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-written-oral" element={ <TabulationWrittenOral /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-activities" element={ <TabulationActivities /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-summative" element={ <TabulationSummative /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-formative" element={ <TabulationFormative /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/lpcd" element={ <TabulationLPCD /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/bco" element={ <TabulationBCO /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/dpls" element={ <TabulationDPLS /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-nine-ten" element={ <TabulationNineTen /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "me" } role={ "staff" } /> }>
          <Route path="/tabulation-eleven-twelve" element={ <TabulationElevenTwelve /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "ar" } role={ "staff" } /> }>
          <Route path="/academic-report" element={ <AcademicReport /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "ar" } /> }>
          <Route path="/academic-report-student" element={ <AcademicReportStudent /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "ar" } role={ "staff" } /> }>
          <Route path="/result-remarks" element={ <ResultRemarks /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "fe" } /> }>
          <Route path="/fees" element={ <Fees /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "sp" } /> }>
          <Route path="/student-profile" element={ <StudentProfile /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "sp" } /> }>
          <Route path="/student-profile-edit" element={ <StudentProfileEdit /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "tp" } role={ "staff" } /> }>
          <Route path="/staff-profile" element={ <StaffProfile /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "tp" } role={ "staff" } /> }>
          <Route path="/staff-profile-edit" element={ <StaffProfileEdit /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "sp" } role={ "staff" } /> }>
          <Route path="/staff-student-profile" element={ <StaffStudentProfile /> } />
          <Route path="/staff-student-profile-edit/:student_id/:typeImage" element={ <StaffStudentProfilePhotoEdit /> } />
          {/* <Route path="/staff-student-profile-edit/:student_id" element={ <CreateHomeWork111 /> } /> */ }
        </Route>
        <Route element={ <PrivateRoute page={ "sp" } role={ "staff" } /> }>
          <Route path="/student-list" element={ <StudentList /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "sp" } role={ "staff" } /> }>
          <Route path="/student-details" element={ <StudentDetails /> } />
        </Route>

        <Route element={ <PrivateRoute page={ "al" } /> }>
          <Route path="/almanac" element={ <Almanac /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "tt" } /> }>
          <Route path="/time-table" element={ <TimeTable /> } />
        </Route>
        <Route element={ <PrivateRoute page={ "ac" } /> }>
          <Route path="/academic-calendar" element={ <AcademicCalendar /> } />
        </Route>

        <Route element={ <PrivateRoute /> }>
          <Route path="/holiday-list" element={ <HolidayList /> } />
        </Route>

        <Route element={ <PrivateRoute /> }>
          <Route path="/contact-us" element={ <ContactUs /> } />
        </Route>

        <Route element={ <PrivateRoute /> }>
          <Route path="/payment-success" element={ <PaymentSuccess /> } />
        </Route>
        <Route element={ <PrivateRoute /> }>
          <Route path="/payment-failed" element={ <PaymentFailed /> } />
        </Route>

        <Route element={ <PrivateRoute /> }>
          <Route path="/no-permission" element={ <NoPermission /> } />
        </Route>
      </Route>

      {/* <Route element={<ErrorLayout />}>
        <Route path="*" element={<NotFound />} />
      </Route> */}
    </Routes>
  );
};

export default MainRoutes;