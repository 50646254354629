import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import { Input, Row, Col, Select, Form, Button, Switch, Space, Upload, Modal } from "antd";
import { postRequest } from "../../axios";
import { PDFDocument } from "pdf-lib";
import PageHeader from "../common/PageHeader";
import
{
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import
{
  getSessionData,
  getUserData,
  getSchoolData,
} from "../../utils/Helpers";


import ImageUploading from "react-images-uploading";
import { ImageCropper } from "../../utils/cropUtils";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useDebounceEffect } from "../../utils/image/useDebounceEffect";
import { canvasPreview } from "../../utils/image/canvasPreview";


const ImageUploadingButton = ( { value, onChange, ...props } ) =>
{
  const [ loading, setLoading ] = useState( false );

  // ✅ Handle PDF file selection
  const handleFileChange = async ( event ) =>
  {
    // type="file" accept="image/*"
    const file = event.target.files[ 0 ];
    onChange( file );
  };



  return (
    <>
      <input type="file" accept="image/*" onChange={ handleFileChange } hidden id="imgUpload" />
      <Button
        type="secondary"
        onClick={ () => document.getElementById( "imgUpload" ).click() }
        { ...props }
        disabled={ loading }
      >
        { loading ? "Uploading..." : "Upload Image" }
      </Button>
    </>
  );
};

const PdfUploadingButton = ( { value, onChange, ...props } ) =>
{
  const [ loading, setLoading ] = useState( false );

  // ✅ Handle PDF file selection
  const handleFileChange = async ( event ) =>
  {
    const file = event.target.files[ 0 ];
    if ( file && file.type === "application/pdf" )
    {
      setLoading( true );
      const compressedPdf = await compressPdf( file );
      const base64Pdf = await convertToBase64( compressedPdf );
      setLoading( false );

      // check file size of base64Pdf is under 4.80 MB or not, if not, then message error anf send null
      if ( base64Pdf.length > 5 * 1024 * 1024 )
      {
        alert( "Maximum size for file upload is 5MB." );
        // send antd message
        ErrorNotificationMsg( "Maximum size for file upload is 5MB." );
        // send null to onChange
        onChange( null ); // ✅ Pass null to onChange if size exceeds limit
      } else
      {
        onChange( base64Pdf ); // ✅ Pass Base64 PDF string to onChange
      }

    } else
    {
      alert( "Please upload a valid PDF file." );
    }
  };

  // ✅ Function to compress PDF
  const compressPdf = async ( file ) =>
  {
    const reader = new FileReader();
    return new Promise( ( resolve ) =>
    {
      reader.onload = async () =>
      {
        const pdfDoc = await PDFDocument.load( reader.result );
        const compressedPdf = await pdfDoc.save( { useObjectStreams: false } ); // ✅ Compress PDF
        const blob = new Blob( [ compressedPdf ], { type: "application/pdf" } );
        resolve( blob );
      };
      reader.readAsArrayBuffer( file );
    } );
  };

  // ✅ Convert Blob to Base64
  const convertToBase64 = ( blob ) =>
  {
    return new Promise( ( resolve ) =>
    {
      const reader = new FileReader();
      reader.onloadend = () => resolve( reader.result.split( "," )[ 1 ] ); // ✅ Extract Base64 content only
      reader.readAsDataURL( blob );
    } );
  };

  return (
    <>
      <input type="file" accept="application/pdf" onChange={ handleFileChange } hidden id="pdfUpload" />
      <Button
        type="primary"
        onClick={ () => document.getElementById( "pdfUpload" ).click() }
        { ...props }
        disabled={ loading }
      >
        { loading ? "Uploading..." : "Upload PDF" }
      </Button>
    </>
  );
};

// const ImageCropper = ( {
//   open,
//   image,
//   onComplete,
//   containerStyle,
//   ...props
// } ) =>
// {
//   const [ crop, setCrop ] = useState( { x: 0, y: 0 } );
//   const [ zoom, setZoom ] = useState( 1 );
//   const [ croppedAreaPixels, setCroppedAreaPixels ] = useState( null );

//   return (
//     <Modal
//       open={ open }
//       title="Crop Image"
//       footer={ null }
//       width={ 500 }
//       centered
//     >
//       <div style={ { position: "relative", width: "100%", height: 400 } }>
//         <Cropper
//           image={ image }
//           crop={ crop }
//           zoom={ zoom }
//           // aspect={ undefined } // ✅ Free Crop (Any Ratio)
//           // zoom={ 1 } // ✅ Keeps the image fixed, no zooming
//           aspect={ undefined } // ✅ Fully free crop (any aspect ratio)
//           restrictPosition={ false } // ✅ Crop box can move freely
//           showGrid={ true } // ✅ Optional, shows crop grid
//           onCropChange={ setCrop }
//           onCropComplete={ ( _, croppedAreaPixels ) => setCroppedAreaPixels( croppedAreaPixels ) }
//           onZoomChange={ setZoom }
//           { ...props }
//         />
//       </div>
//       <div style={ { textAlign: "right", marginTop: 16 } }>
//         <Button
//           type="primary"
//           onClick={ () => onComplete( cropImage( image, croppedAreaPixels, console.log ) ) }
//         >
//           Finish
//         </Button>
//       </div>
//     </Modal>
//   );
// };



const { Option } = Select;
const { TextArea } = Input;


function centerAspectCrop ( mediaWidth, mediaHeight, aspect )
{
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const CreateNoticeBoard = ( props ) =>
{
  const navigate = useNavigate();
  const formRef = useRef();
  const [ state, setState ] = useState( {
    edit_mode: "",
    posted_on: null,
    category: null,
    subject: null,
    description: null,
    comment_enable: 0,
    school_code: null,
    is_draft: "0",
    class_code: null,
    student_list: [],
    projectDocuments: [],
    uploadedFiles: [],
  } );

  const [ btnLoading, setBtnLoading ] = useState( false );
  const [ categoryList, setCategoryList ] = useState( [] );
  const [ classList, setClassList ] = useState( [] );
  const [ studentList, setStudentList ] = useState( [] );
  const [ switchStatus, setSwitchStatus ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getCategoryList();
    getClassList();
  }, [] );

  const handleChange = ( field, value ) =>
  {
    setState( { ...state, [ field ]: value.target.value } );
  };

  const handleSelectChange = ( field, value ) =>
  {
    setState( { ...state, [ field ]: value } );
  };

  const getCategoryList = async () =>
  {
    setSpinner( true );
    const response = await postRequest( "get-notice-board-category", {
      applyFilter: 0,
    } );
    setCategoryList( response.data.response );
    setSpinner( false );
  };

  const getClassList = async () =>
  {
    setSpinner( true );
    const classRes = await postRequest( "get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    } );

    let classArr = classRes.data.response.as_class_teacher.concat(
      classRes.data.response.as_subject_teacher
    );
    let uniqueClassList = classArr.filter(
      ( item, pos ) => classArr.indexOf( item ) === pos
    );
    setClassList( uniqueClassList );
    setSpinner( false );
  };

  const handleClassChange = async ( field, value ) =>
  {
    let stdClass = value.slice( 0, -2 );
    let stdSection = value.slice( -1 );
    setState( { ...state, [ field ]: stdClass, student_list: [] } );
    setSwitchStatus( false );
    formRef.current.setFieldsValue( { student_list: [] } );

    setSpinner( true );
    const studentRes = await postRequest( "get-student-list-by-class", {
      sid: getSessionData().code,
      sclass: stdClass,
      sections: stdSection,
    } );

    setStudentList( studentRes.data.response );
    setSpinner( false );
  };

  const getBase64 = ( img, callback ) =>
  {
    const reader = new FileReader();
    reader.addEventListener( "load", () => callback( reader.result ) );
    reader.readAsDataURL( img );
  };

  function sleep ( ms )
  {
    return new Promise( ( resolve ) => setTimeout( resolve, ms ) );
  }

  const onFinish = async () =>
  {
    setBtnLoading( true );
    setSpinner( true );

    let studentsArr = [];
    state.student_list.map( ( student ) =>
    {
      let sInfo = student.split( "-" );
      studentsArr.push( { id: sInfo[ 0 ], name: sInfo[ 1 ] } );
      return null;
    } );

    let multifile = [];
    croppedImage.map( ( img ) =>
    {
      // getBase64( img.file, ( imageUrl ) =>
      // {
      //   img.file = imageUrl.replace( "data:", "" ).replace( /^.+,/, "" );
      // } );
      let myPhoto = img.replace( "data:image/png;base64,", "" )
      console.log( myPhoto )
      multifile.push( myPhoto );
      return null;
    } );

    // if myPdf not null then push to multifile
    if ( myPdf )
    {
      multifile.push( myPdf );
    }
    // if projectDocuments not null then push to multifile

    await sleep( state.projectDocuments.length * 1000 );

    const payload = {
      edit_mode: "",
      session_id: getSessionData().code,
      posted_on: moment().format( "YYYY-MM-DD" ),
      category: state.category,
      subject: state.subject,
      description: state.description,
      comment_enable: state.comment_enable,
      school_code: getSchoolData().school_code,
      is_draft: state.is_draft,
      sdata: {
        class_code: state.class_code,
        edit_student_list: [],
        student_list: studentsArr,
        staff_list: [],
      },
      multifile: multifile,
    };

    try
    {
      await postRequest( "add-notice-board", payload );

      setBtnLoading( false );
      setSpinner( false );
      SuccessNotificationMsg( "Success", "Notice Board created successfully" );
      navigate( "/notice-board" );
    } catch ( error )
    {
      setBtnLoading( false );
      setSpinner( false );
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  const handleProjectDocumentChange = ( images ) =>
  {
    setState( { ...state, projectDocuments: images } );
  };

  const handleDocumentDelete = ( doc ) =>
  {
    let documents = state.projectDocuments;
    let documentIndex = documents.findIndex(
      ( res ) => res.file.uid === doc.file.uid
    );
    documents.splice( documentIndex, 1 );
    setState( { ...state, projectDocuments: documents } );
  };

  const onSwitchChange = async ( status ) =>
  {
    let studentsArr = [];
    if ( status )
    {
      studentList.map( ( s ) =>
      {
        studentsArr.push( s.student_class_id + "-" + s.student_name );
        return null;
      } );
    }

    setSwitchStatus( status );
    setState( { ...state, student_list: studentsArr } );
    formRef.current.setFieldsValue( { student_list: studentsArr } );
  };




  // Todo: Crop
  const [ myPdf, setMyPdf ] = useState( null );
  const [ croppedImage, setCroppedImage ] = useState( [] );
  const [ dialogOpen, setDialogOpen ] = useState( false );

  const [ imageSize, setImageSize ] = useState( { width: 0, height: 0 } );












  const [ openModel, setOpenModel ] = useState( false );
  const [ imgSrc, setImgSrc ] = useState( '' );
  const previewCanvasRef = useRef( null );
  const imgRef = useRef( null );
  const hiddenAnchorRef = useRef( null );
  const blobUrlRef = useRef( '' );
  const [ crop, setCrop ] = useState();
  const [ completedCrop, setCompletedCrop ] = useState();
  const [ scale, setScale ] = useState( 1 );
  const [ rotate, setRotate ] = useState( 0 );
  // const [ aspect, setAspect ] = useState( 16 / 9 );
  const [ aspect, setAspect ] = useState( null ); // 🔥 No fixed aspect ratio


  function onSelectFile ( file )
  {
    if ( file )
    {
      setOpenModel( true );
      setCrop( undefined );
      const reader = new FileReader();
      reader.addEventListener( 'load', () => setImgSrc( reader.result?.toString() || '' ) );
      reader.readAsDataURL( file );
    }
  }

  function onImageLoad ( e )
  {
    if ( aspect )
    {
      const { width, height } = e.currentTarget;
      setCrop( centerAspectCrop( width, height, aspect ) );
    }
  }



  useDebounceEffect(
    async () =>
    {
      if ( completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current )
      {
        canvasPreview( imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate );
      }
    },
    100,
    [ completedCrop, scale, rotate ]
  );




  async function getCroppedImageAsBase64 ( imgRef )
  {
    console.log( imgRef )
    const image = imgRef.current;  // ✅ Correct reference
    const previewCanvas = previewCanvasRef.current;

    if ( !image )
    {
      console.error( 'Image element not found' );
      return null;
    }
    if ( !previewCanvas )
    {
      console.error( 'Preview canvas not found' );
      return null;
    }
    if ( !completedCrop?.width || !completedCrop?.height )
    {
      console.error( 'Invalid crop dimensions', completedCrop );
      return null;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = document.createElement( 'canvas' ); // Use a normal canvas
    offscreen.width = completedCrop.width * scaleX;
    offscreen.height = completedCrop.height * scaleY;

    const ctx = offscreen.getContext( '2d' );
    if ( !ctx )
    {
      console.error( 'No 2D context available' );
      return null;
    }

    ctx.drawImage(
      image, // 🟢 Now it's an actual <img> element
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    return new Promise( ( resolve ) =>
    {
      resolve( offscreen.toDataURL( 'image/png' ) ); // ✅ Returns Base64 string
    } );
  }


  // 🔹 Example Usage:
  async function handleCropComplete ( imgRef )
  {
    const base64Image = await getCroppedImageAsBase64( imgRef );
    if ( base64Image )
    {
      setCroppedImage( ( prev ) => [ ...prev, base64Image ] ); // ✅ Adds new cropped image
      setOpenModel( false );

    }
  }




  return (
    <div id="content">
      <PageHeader
        pageTitle="Notice Board"
        pageIcon={ <i className="subheader-icon fal fa-file-edit"></i> }
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Notice Board</h2>
              <div className="panel-toolbar">
                <Link
                  to="/notice-board"
                  className="btn btn-sm btn-info waves-effect waves-themed"
                >
                  <i className="fal fa-clipboard-list"></i> View Notice
                  Board
                </Link>
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content p-0">
                <Form
                  onFinish={ onFinish }
                  autoComplete="off"
                  layout="vertical"
                  ref={ formRef }
                >
                  <div className="panel-content">
                    <Row gutter={ [ 15 ] }>
                      <Col xs={ 24 } sm={ 12 } lg={ 8 }>
                        <Form.Item
                          name="class_code"
                          label="Class"
                          rules={ [
                            {
                              required: true,
                              message: "Please select class!",
                            },
                          ] }
                        >
                          <Select
                            placeholder="Select Class"
                            onChange={ ( value ) =>
                              handleClassChange( "class_code", value )
                            }
                          >
                            { !!classList &&
                              classList.map( ( s ) => (
                                <Option key={ s } value={ s }>
                                  { s }
                                </Option>
                              ) ) }
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={ 24 } sm={ 12 } lg={ 16 }>
                        <Form.Item
                          name="student_list"
                          label="Roll(s)"
                          rules={ [
                            {
                              required: true,
                              message: "Please select students!",
                            },
                          ] }
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select Rolls"
                            onChange={ ( value ) =>
                              handleSelectChange( "student_list", value )
                            }
                            value={ state.student_list }
                          >
                            { !!studentList &&
                              studentList.map( ( s ) => (
                                <Option
                                  key={ s.roll_no }
                                  value={
                                    s.student_class_id +
                                    "-" +
                                    s.student_name
                                  }
                                >
                                  { s.roll_no + " - " + s.student_name }
                                </Option>
                              ) ) }
                          </Select>
                        </Form.Item>

                        <>
                          Select All{ " " }
                          <Switch
                            onChange={ onSwitchChange }
                            checked={ switchStatus }
                            disabled={
                              state.class_code !== null ? false : true
                            }
                          />
                        </>
                      </Col>
                    </Row>
                    <hr />

                    <Row gutter={ [ 15 ] }>
                      <Col xs={ 24 } sm={ 12 } lg={ 12 }>
                        <Form.Item
                          label="Subject"
                          name="subject"
                          rules={ [
                            {
                              required: true,
                              message: "Please enter subject",
                            },
                          ] }
                        >
                          <Input
                            onChange={ ( value ) =>
                              handleChange( "subject", value )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={ 24 } sm={ 6 } lg={ 6 }>
                        <Form.Item
                          name="category"
                          label="Category"
                          rules={ [
                            {
                              required: true,
                              message: "Please select category!",
                            },
                          ] }
                        >
                          <Select
                            placeholder="Select Category"
                            onChange={ ( value ) =>
                              handleSelectChange( "category", value )
                            }
                          >
                            { !!categoryList &&
                              categoryList.map( ( s ) => (
                                <Option key={ s.id } value={ s.id }>
                                  { s.name }
                                </Option>
                              ) ) }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={ 24 } sm={ 6 } lg={ 6 }>
                        <Form.Item
                          name="comment_enable"
                          label="Enable Comment"
                        >
                          <Select
                            defaultValue={ 0 }
                            onChange={ ( value ) =>
                              handleSelectChange( "comment_enable", value )
                            }
                          >
                            <Option value={ 0 }>No</Option>
                            <Option value={ 1 }>Yes</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={ [ 15 ] }>
                      <Col xs={ 24 } sm={ 12 } lg={ 24 }>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={ [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter description",
                            },
                          ] }
                        >
                          <TextArea
                            rows={ 4 }
                            onChange={ ( value ) =>
                              handleChange( "description", value )
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={ 24 } sm={ 12 } lg={ 24 }>
                        {/* <NoticeBoardDocumentUpload
                          stateValues={ state }
                          handleProjectDocumentChange={
                            handleProjectDocumentChange
                          }
                          handleDocumentDelete={ handleDocumentDelete }
                        /> */}

                        <>

                          <div className="secction-title" id="area3">
                            <h3>Attachment(s)</h3>
                            <p className="ant-upload-hint">
                              File upload limit is 10.
                            </p>
                          </div>


                          { croppedImage.length < 10 && (
                            <>
                              <ImageUploadingButton
                                value={ "" }
                                onChange={ ( newImage ) =>
                                {
                                  onSelectFile( newImage );
                                } }
                              />
                            </>
                          ) }


                          {/* if myPdf is null, then show */ }
                          { myPdf === null && (
                            <PdfUploadingButton
                              className="ml-2"
                              onChange={ ( base64Pdf ) => setMyPdf( base64Pdf ) }
                            />
                          ) }

                          { croppedImage.length > 0 && (
                            <div className="mt-2"
                              style={ { display: "flex", gap: "10px", flexWrap: "wrap" } }>
                              { croppedImage.map( ( img, index ) => (

                                <div style={ {
                                  position: "relative",
                                  display: "inline-block",
                                  width: "130px", // Adjusted for padding
                                  height: "130px"
                                } }>
                                  <button
                                    onClick={ () =>
                                    {
                                      setCroppedImage( ( prevImages ) => prevImages.filter( ( _, i ) => i !== index ) );
                                    } } // Replace with actual remove function
                                    style={ {
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      background: "red",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "50%",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer"
                                    } }
                                  >
                                    ✖
                                  </button>
                                  <img
                                    key={ index }
                                    src={ img }
                                    alt={ `Cropped ${ index }` }
                                    style={ {
                                      width: "120px", // Fixed width
                                      height: "120px", // Fixed height
                                      objectFit: "contain", // Keeps full image visible inside fixed box
                                      borderRadius: "8px",
                                      border: "1px solid #ddd",
                                      padding: "5px",
                                    } }
                                  />
                                </div>

                              ) ) }
                            </div>
                          ) }
                        </>

                      </Col>
                    </Row>
                    <br />
                  </div>



                  <canvas
                    ref={ previewCanvasRef }
                    style={ {
                      display: "none", // Hides the canvas
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop?.width,
                      height: completedCrop?.height
                    } }
                  />


                  {/* { !!imgSrc && (
                    <ReactCrop crop={ crop } onChange={ ( _, percentCrop ) => setCrop( percentCrop ) } onComplete={ ( c ) => setCompletedCrop( c ) } aspect={ aspect } minHeight={ 100 }>
                      <img ref={ imgRef } alt="Crop me" src={ imgSrc } style={ { transform: `scale(${ scale }) rotate(${ rotate }deg)` } } onLoad={ onImageLoad } />
                    </ReactCrop>
                  ) } */}
                  <Modal
                    open={ openModel }
                    title="Crop Image"
                    width={ 500 }
                    onCancel={ () => setOpenModel( false ) } // ✅ Close modal when clicking the cross button
                    footer={ null } // 🔥 Removes OK and Cancel buttons
                  >
                    <div className="row">

                      { !!imgSrc && (
                        <ReactCrop
                          crop={ crop }
                          onChange={ ( _, percentCrop ) => setCrop( percentCrop ) }
                          onComplete={ ( c ) => setCompletedCrop( c ) }
                          aspect={ aspect }
                          minHeight={ 100 }
                        >
                          <img
                            ref={ imgRef }
                            alt="Crop me"
                            src={ imgSrc }
                            style={ { transform: `scale(${ scale }) rotate(${ rotate }deg)` } }
                            onLoad={ onImageLoad }
                          />
                        </ReactCrop>
                      ) }

                    </div>

                    <div style={ { textAlign: "right", marginTop: 16 } }>
                      <Button
                        type="primary"
                        onClick={ () =>
                        {
                          handleCropComplete( imgRef );
                          // setSpinner( true );
                          // onComplete( cropImage( image, croppedAreaPixels, console.log ) );
                          // setSpinner( false );
                        } }
                      >
                        Finish
                      </Button>
                    </div>
                  </Modal>



                  <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                    <Space>
                      <Button
                        type="secondary"
                        htmlType="submit"
                        onClick={ () =>
                          setState( { ...state, is_draft: "1" } )
                        }
                        loading={ btnLoading }
                        className="btn btn-danger ml-auto waves-effect waves-themed"
                      >
                        Draft
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={ () =>
                          setState( { ...state, is_draft: "0" } )
                        }
                        loading={ btnLoading }
                        className="btn btn-primary ml-auto waves-effect waves-themed"
                      >
                        Publish
                      </Button>
                    </Space>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNoticeBoard;
