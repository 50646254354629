import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getUserType } from "../../../utils/Helpers";
import DashboardStaff from "./DashboardStaff";

const ButtonUI = ( props ) =>
{
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={ () => navigate( props.url ) }
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-5 d-block l-h-n m-0 fw-500 text-white">
            { props.title }
            <small className="m-0 l-h-n">{ props.subtitle }</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={ { fontSize: "6rem" } }
        ></i>
      </div>
    </div>
  );
};

const BigButtonUI = ( props ) =>
{
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={ () => navigate( props.url ) }
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-4 d-block l-h-n m-0 fw-500 text-white">
            { props.title }
            <small className="m-0 l-h-n">{ props.subtitle }</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={ { fontSize: "6rem" } }
        ></i>
      </div>
    </div>
  );
};

const MobileDashboard = ( props ) =>
{
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    setSpinner( false );
  }, [] )

  return (
    <>
      <DashboardStaff />
    </>
  );
};

export default MobileDashboard;
