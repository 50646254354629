import React from 'react';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop';
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

class CropModal extends React.Component
{

    constructor ( props )
    {
        super( props );
        this.state = {
            croppedFile: null, // Store the cropped image
        };
    }

    state = {
        myFile: null,
        image: null,
        src: null,
        crop: {
            unit: "px",
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        },
        croppedImg: null,
    };


    componentDidMount ()
    {
        const { myFile } = { ...this.props };

        if ( myFile === null )
        {
            return;
        } else
        {
            if ( myFile )
            {
                const reader = new FileReader();
                reader.addEventListener( 'load', () => this.setState( { src: reader.result } ), false );
                reader.readAsDataURL( myFile );
            }
            this.setState( { myFile: myFile } );
        }
    };

    onCropComplete = async () =>
    {
        const croppedImg = await this.getCroppedImg();
        this.setState( { croppedImg } );
    };
    getCroppedImg = async () =>
    {
        const { image, myFile, crop } = this.state;
        if ( !image ) return null;

        const canvas = document.createElement( "canvas" );
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext( "2d" );

        // **Set up high-quality drawing**
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform( pixelRatio, 0, 0, pixelRatio, 0, 0 );
        ctx.imageSmoothingQuality = "high";

        // **Draw Cropped Image**
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise( ( resolve, reject ) =>
        {
            let quality = 1; // Start with full quality
            let maxSize = 100 * 1024; // 100 KB

            const compressImage = () =>
            {
                canvas.toBlob( ( blob ) =>
                {

                    console.log( { blob, quality } )
                    if ( !blob ) return reject( "Compression failed" );

                    if ( blob.size <= maxSize || quality <= 0.01 )
                    {
                        // ✅ Image is within 100 KB or lowest quality reached
                        blob.name = myFile.name;
                        const croppedUrl = URL.createObjectURL( blob );
                        this.setState( { croppedImg: blob, croppedImgUrl: croppedUrl } );
                        resolve( blob );
                    } else
                    {
                        // ❌ Try again with lower quality
                        quality -= 0.01;
                        compressImage();
                    }
                }, "image/jpeg", quality );
            };

            compressImage();
        } );
    };

    getCompressedImage = () =>
    {

    };


    render ()
    {
        const { isModalopen, onOk, handleSubmit, onCancle } = { ...this.props };
        const { croppedImg } = { ...this.state };
        return (
            <Modal
                title="Crop Image" width={ 1000 }
                open={ isModalopen ? true : false }
                onOk={ () =>
                {
                    if ( handleSubmit )
                    {
                        if ( croppedImg === null || croppedImg === undefined )
                        {
                            ErrorNotificationMsg( "Please crop to select image" );
                        } else
                        {
                            handleSubmit( croppedImg );
                            if ( onOk ) { onOk() }
                        }
                    }
                } }
                onCancel={ onCancle ? onCancle : () => { } }
            >

                <div>
                    <h5>Original:</h5>
                    <img
                        src={ URL.createObjectURL( this.state.myFile ) }
                        alt="Cropped Preview"
                        style={ { maxWidth: '100%', maxHeight: '300px' } }
                    />
                </div>
                { this.state.src && (
                    <ReactCrop
                        src={ this.state.src }
                        crop={ this.state.crop }
                        onComplete={ this.onCropComplete }
                        onChange={ ( newCrop ) => this.setState( { crop: newCrop } ) }
                        onImageLoaded={ ( image ) =>
                        {
                            this.setState( { image } );
                        } }
                    >
                    </ReactCrop>
                ) }
                { croppedImg && (
                    <div>
                        <h5>Preview:</h5>
                        <img
                            src={ URL.createObjectURL( croppedImg ) }
                            alt="Cropped Preview"
                            style={ { maxWidth: '100%', maxHeight: '300px' } }
                        />
                    </div>
                ) }
            </Modal>
        );
    }
}

export default CropModal;