import { Button, Modal } from "antd";
import { useState } from "react";
import Cropper from "react-easy-crop";

const createImage = ( url ) =>
    new Promise( ( resolve, reject ) =>
    {
        const image = new Image();
        image.addEventListener( "load", () => resolve( image ) );
        image.addEventListener( "error", ( error ) => reject( error ) );
        image.setAttribute( "crossOrigin", "anonymous" ); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    } );

// Copy from "https://codesandbox.io/s/react-easy-crop-demo-with-cropped-output-q8q1mnr01w?from-embed=&file=/src/cropImage.js:0-2289"
async function getCroppedImg ( imageSrc, pixelCrop )
{
    const image = await createImage( imageSrc );
    const canvas = document.createElement( "canvas" );
    const ctx = canvas.getContext( "2d" );

    const maxSize = Math.max( image.width, image.height );
    const safeArea = 2 * ( ( maxSize / 2 ) * Math.sqrt( 2 ) );

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData( 0, 0, safeArea, safeArea );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
        data,
        Math.round( 0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x ),
        Math.round( 0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y )
    );


    return canvas.toDataURL( "image/jpeg" );
}

const compressBase64Image = ( base64String, maxSize = 100 * 1024 ) =>
{
    return new Promise( ( resolve, reject ) =>
    {
        const img = new Image();
        img.src = base64String;
        img.onload = () =>
        {
            const canvas = document.createElement( "canvas" );
            const ctx = canvas.getContext( "2d" );

            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage( img, 0, 0, width, height );

            const compressImage = ( quality ) =>
            {
                console.log( { quality } );
                canvas.toBlob(
                    ( blob ) =>
                    {
                        if ( blob.size <= maxSize )
                        {
                            const reader = new FileReader();
                            reader.readAsDataURL( blob );
                            reader.onloadend = () => resolve( reader.result ); // ✅ Return compressed Base64
                        } else if ( quality > 0.1 )
                        {
                            compressImage( quality - 0.1 ); // Reduce quality further if needed
                        } else
                        {
                            const reader = new FileReader();
                            reader.readAsDataURL( blob );
                            reader.onloadend = () => resolve( reader.result ); // ✅ Return compressed Base64
                            // reject( new Error( "Cannot compress below 100 KB" ) );
                        }
                    },
                    "image/jpeg",
                    quality
                );
            };

            compressImage( 0.1 ); // Start with 80% quality and reduce if needed
        };
        img.onerror = ( error ) => reject( error );
    } );
};


export const cropImage = async ( image, croppedAreaPixels, onError ) =>
{

    try
    {
        const croppedImage = await getCroppedImg( image, croppedAreaPixels );
        const compresedImage = await compressBase64Image( croppedImage, 100 );

        console.log( typeof compresedImage );
        console.log( compresedImage );

        return compresedImage;
    } catch ( err )
    {
        onError( err );
    }
};




export const ImageCropper = ( {
    setSpinner,
    open,
    image,
    aspect = undefined, // ✅ Default is Free Crop
    onComplete,
    containerStyle,
    onClose, // ✅ Add this prop to handle modal close
    ...props
} ) =>
{


    const [ crop, setCrop ] = useState( { x: 0, y: 0 } );
    const [ zoom, setZoom ] = useState( 1 );
    const [ croppedAreaPixels, setCroppedAreaPixels ] = useState( null );

    return (
        <Modal
            open={ open }
            title="Crop Image"
            footer={ null }
            width={ 500 }
            centered
            onCancel={ onClose } // ✅ Close modal when clicking the cross button
        >
            <div style={ { position: "relative", width: "100%", height: 400 } }>
                <Cropper
                    image={ image }
                    crop={ crop }
                    zoom={ zoom }
                    // aspect={ undefined } // ✅ Free Crop (Any Ratio)
                    // zoom={ 1 } // ✅ Keeps the image fixed, no zooming
                    aspect={ aspect } // ✅ Dynamically set aspect ratio
                    restrictPosition={ false } // ✅ Crop box can move freely
                    showGrid={ true } // ✅ Optional, shows crop grid
                    onCropChange={ setCrop }
                    onCropComplete={ ( _, croppedAreaPixels ) => setCroppedAreaPixels( croppedAreaPixels ) }
                    onZoomChange={ setZoom }
                    { ...props }
                />
            </div>

            <div style={ { textAlign: "right", marginTop: 16 } }>
                <Button
                    type="primary"
                    onClick={ () =>
                    {
                        setSpinner( true );
                        onComplete( cropImage( image, croppedAreaPixels, console.log ) );
                        setSpinner( false );
                    } }
                >
                    Finish
                </Button>
            </div>
        </Modal>
    );
};
