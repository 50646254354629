import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Modal, Button, Select, Typography } from "antd";

import { postRequest } from "../../axios";
import { getSessionData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const { Title, Text } = Typography;
const { Option } = Select;

const StudentListLPCD = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ questionList, setQuestionist ] = useState( {} );
  const [ selectStudent, setSelectStudent ] = useState( {} );
  const [ selectStudentId, setSelectStudentId ] = useState( 0 );
  const setSpinner = useOutletContext();

  const [ showModel, setShowModel ] = useState( false );

  useEffect( () =>
  {
    getQuestion();
    getTabulation();
  }, [] );

  const getQuestion = async () =>
  {
    const response = await postRequest(
      "exam-marks-tabulation-lpcd-setting",
      {}
    );

    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      setQuestionist( response.data.response );
    }
  };

  // Mapping object for category labels
  const categoryLabels = {
    poi: "Pattern of Intelligence",
    aoi: "Area of Interest",
    pa: "Positive Attitude",
    ea: "Exceptional Ability",
    foa: "Features of Anxiety",
    lg: "Learning Gaps",
    sld: "Specific Learning Difficulties",
  };

  const getTabulation = async () =>
  {

    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-lpcd-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
        examId: props.examId,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }

  };

  const hideModelFunction = () =>
  {

    setSelectStudent( {} );
    setSelectStudentId( 0 );
    setShowModel( false );
  };

  const prevFun = () =>
  {
    console.log( "11" );
    // if selectStudentId ==  0
    if ( selectStudentId == 0 )
    {
      ErrorNotificationMsg( "Please click on Next" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );

    // set student index -1
    const newId = selectStudentId - 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );

    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to previous student" );

  };

  const nextFun = () =>
  {
    // if selectStudentId == length of marks - 1
    if ( selectStudentId == marksList.length - 1 )
    {
      ErrorNotificationMsg( "Please click on Previous" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );
    // set student index +1
    const newId = selectStudentId + 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );
    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to next student" );
  };

  const showModelFunction = ( indexId, student ) =>
  {
    setSelectStudent( student );
    setSelectStudentId( indexId );

    setShowModel( true );

  };

  const handleSelectChange = ( category, value ) =>
  {


    selectStudent.marks[ category ] = value;
    setSelectStudent( ( prevState ) => ( {
      ...prevState,
      marks: {
        ...prevState.marks,
        [ category ]: value
      }
    } ) );

    // setMarksList update
    setMarksList( ( prevList ) =>
      prevList.map( ( student ) =>
        student.classId === selectStudent.classId
          ? { ...student, marks: selectStudent.marks }
          : student
      )
    );

  };

  const handleMarksChange = async () =>
  {
    let emptyCount = 0;

    if ( selectStudent.marks.poi == null || selectStudent.marks.poi === "" )
      emptyCount++;
    if ( selectStudent.marks.aoi == null || selectStudent.marks.aoi === "" )
      emptyCount++;
    if ( selectStudent.marks.pa == null || selectStudent.marks.pa === "" )
      emptyCount++;
    if ( selectStudent.marks.ea == null || selectStudent.marks.ea === "" )
      emptyCount++;
    if ( selectStudent.marks.foa == null || selectStudent.marks.foa === "" )
      emptyCount++;
    if ( selectStudent.marks.lg == null || selectStudent.marks.lg === "" )
      emptyCount++;
    if ( selectStudent.marks.sld == null || selectStudent.marks.sld === "" )
      emptyCount++;

    console.log( "emptyCount", emptyCount );
    console.log( "selectStudent", selectStudent );

    if ( emptyCount !== 0 )
    {
      ErrorNotificationMsg( " Please fill in the all missing data." );
      return;
    }

    const payload = {
      examId: props.examId, //
      form_data: {
        marks: [
          {
            classId: selectStudent.classId,
            poi: selectStudent.marks.poi,
            aoi: selectStudent.marks.aoi,
            pa: selectStudent.marks.pa,
            ea: selectStudent.marks.ea,
            foa: selectStudent.marks.foa,
            lg: selectStudent.marks.lg,
            sld: selectStudent.marks.sld,
          },
        ],
      },
    };


    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-lpcd-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Success", "Marks successfully updated." );
        // hideModelFunction( false );
        nextFun();
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr d-flex justify-content-between align-items-center">
              <h2 className="m-0">Student List of Class: { props.classSection }</h2>
              <div className="panel-toolbar">
                {
                  tabulation?.lockTabulation === 0 && (
                    <>
                      <Button
                        onClick={ async () =>
                        {
                          setSpinner( true );
                          const responseNewList = await postRequest(
                            "exam-marks-tabulation-lpcd-all-student",
                            {
                              sessionCode: getSessionData().rcode,
                              classSection: props.classSection,
                              examId: props.examId,
                            }
                          );

                          if ( responseNewList.data.error > 0 )
                          {
                            ErrorNotificationMsg( responseNewList.data.errmsg );
                            setSpinner( false );
                            return;
                          }
                          setTabulation( responseNewList.data.response.data );
                          setMarksList( responseNewList.data.response.data.students );
                          const checkData = responseNewList.data.response.data.students;

                          let setLock = false;

                          for ( let i = 0; i < checkData.length; i++ )
                          {
                            if ( checkData[ i ].marks?.aoi == undefined || checkData[ i ].marks?.aoi == null || checkData[ i ].marks?.aoi === "" )
                            {
                              setLock = false;
                              break;
                            } else
                            {
                              setLock = true;
                            }
                          }


                          if ( setLock == false )
                          {
                            ErrorNotificationMsg( "Please fill in the all missing data." );
                            setSpinner( false );
                            return;
                          }

                          const response = await postRequest( "exam-marks-finalize", {
                            // schoolCode: getSchoolData().school_code,
                            sessionCode: getSessionData().rcode,
                            classSection: props.classSection,
                            term: props.examId,
                            // subjectId: props.subjectId,
                            // userId: getUserData().tid,
                            examType: "lpcd",
                          } );

                          setSpinner( false );
                          if ( response.data.error > 0 )
                          {
                            ErrorNotificationMsg( response.data.errmsg );
                          }


                          getTabulation();
                        } }
                        className="btn btn-danger waves-effect waves-themed m-2"
                      >
                        Finalize
                      </Button>
                    </>
                  )
                }
              </div>
            </div>



            <div className="panel-container show">
              <div className="panel-content">
                <Form autoComplete="off" layout="vertical" ref={ formRef }>
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student, indexId ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td>
                                  <strong>{ student?.stdName }</strong>
                                </td>

                                {/* Add button */ }
                                <td className="text-center">
                                  <button
                                    className={ `btn btn-sm ${ student.marks.aoi
                                      ? "btn-success"
                                      : "btn-outline-info"
                                      }` }
                                    onClick={ () => showModelFunction( indexId, student ) }
                                  >
                                    { student.marks.aoi ? "Edit" : "Add" }
                                  </button>
                                </td>
                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>

                <Modal
                  title="Learning Perspective of Cognitive Domain [LPCD]"
                  open={ showModel }
                  onOk={ hideModelFunction }
                  onCancel={ hideModelFunction }
                  footer={ null }>
                  <Row gutter={ [ 15 ] }>
                    <Col span={ 24 }>
                      <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
                        <Text>Enter details for : <strong>{ selectStudent?.stdName }</strong>, Roll - <strong>{ selectStudent?.stdRoll }</strong></Text>
                      </div>
                    </Col>
                  </Row>

                  <Form
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Row gutter={ [ 15 ] } className="mt-4">
                      { Object.entries( questionList ).map( ( [ category, items ] ) => (
                        <Col xs={ 24 } sm={ 12 } lg={ 12 }>
                          <Form.Item label={ categoryLabels[ category ] || category }>
                            <Select
                              disabled={ tabulation?.lockTabulation === 1 } // Disable Select when lockTabulation is 1
                              value={ selectStudent.marks?.[ category ] || "" }
                              onChange={ ( value ) =>
                                handleSelectChange( category, value )
                              }>
                              <Option value="">Select...</Option>
                              { items.map( ( item, idx ) => (
                                <Option key={ `${ category }-${ idx }` } value={ item }>
                                  { item }
                                </Option>
                              ) ) }
                            </Select>
                          </Form.Item>
                        </Col>
                      ) ) }
                    </Row>
                  </Form>

                  <div className="panel-content mt-2 d-flex flex-row justify-content-between">
                    {/* Previous Button */ }
                    <Button
                      type="secondary"
                      onClick={ prevFun }
                      hidden={ selectStudentId == 0 }
                    >
                      &lt; Prev
                    </Button>

                    {/* Submit Button (Centered) */ }
                    { tabulation?.lockTabulation === 0 && (
                      <>
                        <Button
                          type="primary"
                          onClick={ handleMarksChange }
                          hidden={ tabulation?.lockTabulation === 1 }
                        >
                          Submit & Next
                        </Button>
                      </>
                    ) }


                    {/* Next Button */ }
                    <Button
                      type="secondary"
                      onClick={ nextFun }
                      hidden={ selectStudentId == marksList.length - 1 }
                    >
                      Next &gt;
                    </Button>
                  </div>

                </Modal>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default StudentListLPCD;
