import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getSchoolData, getUserData, getSchoolMenu } from "../../../utils/Helpers";
// import GoogleAuth from "../authentication/GoogleAuth";

const ButtonUI = ( props ) =>
{
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={ () => navigate( props.url ) }
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-5 d-block l-h-n m-0 fw-500 text-white">
            { props.title }
            <small className="m-0 l-h-n">{ props.subtitle }</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={ { fontSize: "6rem" } }
        ></i>
      </div>
    </div>
  );
};

const DashboardStaff = ( props ) =>
{
  const [ clientId, setClientId ] = useState( "" );
  const setSpinner = useOutletContext();

  return (
    <div className="content">

      <div className="row">

        <>

          { getSchoolMenu().indexOf( "me" ) !== -1 &&
            <>
              { getSchoolData().school_type === 'wb-govt' ? (
                <>
                  <ButtonUI
                    title="Formative"
                    subtitle="Tabulation [V-VIII]"
                    url="/mobile-tabulation-formative"
                    icon="fal fa-clipboard-list-check"
                    color="bg-primary-500"
                    history={ props.history }
                  />

                  <ButtonUI
                    title="Summative"
                    subtitle="Tabulation [V-VIII]"
                    url="/mobile-tabulation-summative"
                    icon="fal fa-clipboard-list-check"
                    color="bg-success-500"
                    history={ props.history }
                  />

                  {

                    getUserData().staffType == "ct" &&
                    <>
                      <ButtonUI
                        title="LPCD"
                        subtitle="Learning Perspective of Cognitive Domain [LPCD]"
                        url="/mobile-lpcd"
                        icon="fal fa-clipboard-list-check"
                        color="bg-warning-500"
                        history={ props.history }
                      />
                      <ButtonUI
                        title="BCO"
                        subtitle="Behavioural Cognitive Outcomes [BCO]"
                        url="/mobile-bco"
                        icon="fal fa-clipboard-list-check"
                        color="bg-info-500"
                        history={ props.history }
                      />
                      <ButtonUI
                        title="DPLS"
                        subtitle="Development of Personality and Life Skills [DPLS]"
                        url="/mobile-dpls"
                        icon="fal fa-clipboard-list-check"
                        color="bg-primary-500"
                        history={ props.history }
                      />
                    </>
                  }


                  <ButtonUI
                    title="Tabulation"
                    subtitle="[IX-X]"
                    url="/mobile-tabulation-nine-ten"
                    icon="fal fa-clipboard-list-check"
                    color="bg-warning-500"
                    history={ props.history }
                  />

                  <ButtonUI
                    title="Tabulation"
                    subtitle="[XI-XII]"
                    url="/mobile-tabulation-eleven-twelve"
                    icon="fal fa-clipboard-list-check"
                    color="bg-warning-500"
                    history={ props.history }
                  />


                </>
              ) : (
                <>
                  { getSchoolData().activityExam === 1 && (
                    <ButtonUI
                      title="Tabulation"
                      subtitle="Activities"
                      url="/mobile-tabulation-activities"
                      icon="fal fa-clipboard-list-check"
                      color="bg-success-500"
                      history={ props.history }
                    />
                  ) }

                  <ButtonUI
                    title="Tabulation"
                    subtitle="Written / Oral"
                    url="/mobile-tabulation-written-oral"
                    icon="fal fa-clipboard-list-check"
                    color="bg-danger-500"
                    history={ props.history }
                  />

                </>
              ) }
            </>
          }


        </>




      </div>
    </div>
  );
};

export default DashboardStaff;
