import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const Success = ( props ) =>
{
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    setSpinner( false );
  }, [] );

  return (
    <main className="page-content">
      <div className="menu-on-top fixed-header smart-style-0">
        <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
          <h1 className="page-error color-fusion-500">
            <span className="text-gradient">Success!!</span>
          </h1>
        </div>
      </div>
    </main>
  );
};

export default Success;