import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Space, Modal, Row, Col, Select, Typography } from "antd";
import { postRequest } from "../../axios";
import
{
  getSessionData,
  getSchoolData,
  getUserData,
} from "../../utils/Helpers";

import
{
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";


const { Option } = Select;
const { Title, Text } = Typography;


const StudentListBCO = ( props ) =>
{

  const isMobile = /Mobi|Android|iPhone/i.test( navigator.userAgent ) || window.innerWidth < 768;

  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();
  const [ showModel, setShowModel ] = useState( false );
  const [ selectStudentId, setSelectStudentId ] = useState( 0 );
  const [ selectStudent, setSelectStudent ] = useState( {} );

  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-bco-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
        examId: props.examId,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    console.log( "Checking marks" );
    console.log( event );
    console.log( { event, student, indicator } );

    let inputValue = event.target.value.toUpperCase(); // Convert to uppercase
    console.log( inputValue );

    // Allow only A, B, C, D, or blank
    if ( ![ "A", "B", "C", "D", "" ].includes( inputValue ) )
    {
      event.target.value = "";
      inputValue = "";
      ErrorNotificationMsg( "Grade should be  A or B or C or D " );
    }

    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };

    items[ documentIndex ] = item;
    item.marks[ indicator ] = inputValue;

    setMarksList( items );
  };

  const handleMarksChange = async ( student ) =>
  {

    const validKeys = [ "sa", "cs", "ctc", "els", "ct", "cat", "psadec", "dms", "cps", "aa", "tpo" ]; // Keys to check

    const isValid = validKeys.every(
      ( key ) => student.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( student.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      examId: props.examId,
      form_data: {
        marks: [
          {
            classId: student.classId,
            sa: student.marks.sa,
            cs: student.marks.cs,
            ctc: student.marks.ctc,
            els: student.marks.els,
            ct: student.marks.ct,
            cat: student.marks.cat,
            psadec: student.marks.psadec,
            dms: student.marks.dms,
            cps: student.marks.cps,
            aa: student.marks.aa,
            tpo: student.marks.tpo,
          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-bco-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Successfully updated" );
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };


  const handleMarksChangeModel = async () =>
  {

    const validKeys = [ "sa", "cs", "ctc", "els", "ct", "cat", "psadec", "dms", "cps", "aa", "tpo" ]; // Keys to check

    const isValid = validKeys.every(
      ( key ) => selectStudent.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( selectStudent.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      examId: props.examId,
      form_data: {
        marks: [
          {
            classId: selectStudent.classId,
            sa: selectStudent.marks.sa,
            cs: selectStudent.marks.cs,
            ctc: selectStudent.marks.ctc,
            els: selectStudent.marks.els,
            ct: selectStudent.marks.ct,
            cat: selectStudent.marks.cat,
            psadec: selectStudent.marks.psadec,
            dms: selectStudent.marks.dms,
            cps: selectStudent.marks.cps,
            aa: selectStudent.marks.aa,
            tpo: selectStudent.marks.tpo,
          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-bco-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Successfully updated" );
        nextFun();
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };


  const hideModelFunction = () =>
  {

    setSelectStudent( {} );
    setSelectStudentId( 0 );
    setShowModel( false );
  };

  const prevFun = () =>
  {
    // if selectStudentId ==  0
    if ( selectStudentId == 0 )
    {
      ErrorNotificationMsg( "Please click on Next" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );

    // set student index -1
    const newId = selectStudentId - 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );

    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to previous student" );

  };

  const nextFun = () =>
  {
    // if selectStudentId == length of marks - 1
    if ( selectStudentId == marksList.length - 1 )
    {
      ErrorNotificationMsg( "Please click on Previous" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );
    // set student index +1
    const newId = selectStudentId + 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );
    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to next student" );
  };

  const showModelFunction = ( indexId, student ) =>
  {
    setSelectStudent( student );
    setSelectStudentId( indexId );

    setShowModel( true );

  };


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr d-flex justify-content-between align-items-center">
              <h2 className="m-0">Student List of Class: { props.classSection }</h2>
              <div className="panel-toolbar">
                {
                  tabulation?.lockTabulation === 0 && (
                    <>
                      <Button
                        type="success"
                        onClick={ async () =>
                        {

                          setSpinner( true );

                          const responseNewList = await postRequest(
                            "exam-marks-tabulation-bco-all-student",
                            {
                              sessionCode: getSessionData().rcode,
                              classSection: props.classSection,
                              examId: props.examId,
                            }
                          );

                          if ( responseNewList.data.error > 0 )
                          {
                            ErrorNotificationMsg( responseNewList.data.errmsg );
                            setSpinner( false );
                            return;
                          }

                          setTabulation( responseNewList.data.response.data );
                          setMarksList( responseNewList.data.response.data.students );
                          const checkData = responseNewList.data.response.data.students;

                          let setLock = false;

                          for ( let i = 0; i < checkData.length; i++ )
                          {
                            if ( checkData[ i ].marks.sa == undefined || checkData[ i ].marks.sa == null || checkData[ i ].marks.sa === "" )
                            {
                              setLock = false;
                              break;
                            } else
                            {
                              setLock = true;
                            }
                          }

                          if ( setLock == false )
                          {
                            ErrorNotificationMsg( "Please fill in the all missing data." );
                            setSpinner( false );
                            return;
                          }


                          const response = await postRequest( "exam-marks-finalize", {
                            // schoolCode: getSchoolData().school_code,
                            sessionCode: getSessionData().rcode,
                            classSection: props.classSection,
                            term: props.examId,
                            // subjectId: props.subjectId,
                            // userId: getUserData().tid,
                            examType: "bco",
                          } );

                          setSpinner( false );
                          if ( response.data.error > 0 )
                          {
                            ErrorNotificationMsg( response.data.errmsg );
                          }


                          getTabulation();
                        } }
                        className="btn btn-danger waves-effect waves-themed m-2"
                      >
                        Finalize
                      </Button>
                    </>
                  )
                }
              </div>
            </div>



            <div className="panel-container show">
              <div className="panel-content">

                <div className="form-row">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-danger fade show px-3 py-2">
                      <strong>A</strong> - Very Good, <strong>B</strong> - Good, <strong>C</strong> - Satisfactory, <strong>D</strong> - Requires More Development
                    </div>
                  </div>
                </div>
                {
                  isMobile
                    ? ( <>

                      <Form autoComplete="off" layout="vertical" ref={ formRef }>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                            <thead className="thead-themed">
                              <tr className="text-center">
                                <th>ROLL</th>
                                <th>NAME</th>
                                <th>Action</th>

                              </tr>
                            </thead>
                            <tbody>
                              { marksList &&
                                marksList.map( ( student, indexId ) =>
                                {
                                  return (
                                    <tr key={ student?.classId }>
                                      <td>{ student?.stdRoll }</td>
                                      <td>
                                        <strong>{ student?.stdName }</strong>
                                      </td>

                                      {/* Add button */ }
                                      <td className="text-center">
                                        <button
                                          className={ `btn btn-sm ${ student.marks.sa
                                            ? "btn-success"
                                            : "btn-outline-info"
                                            }` }
                                          onClick={ () => showModelFunction( indexId, student ) }
                                        >
                                          { student.marks.sa ? "Edit" : "Add" }
                                        </button>

                                      </td>
                                    </tr>
                                  );
                                } ) }
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </> )
                    : ( <>
                      <Form autoComplete="off" layout="vertical" ref={ formRef }>

                        <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                            <thead className="thead-themed">
                              <tr className="text-center">
                                <th>ROLL</th>
                                <th>NAME</th>
                                <th>SA</th>
                                <th>CS</th>
                                <th>CTC</th>
                                <th>ELS</th>
                                <th>CT</th>
                                <th>CAT</th>
                                <th>PSADEC</th>
                                <th>DMS</th>
                                <th>CPS</th>
                                <th>AA</th>
                                <th>TPO</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              { marksList &&
                                marksList.map( ( student ) =>
                                {
                                  return (
                                    <tr key={ student?.classId }>
                                      <td>{ student?.stdRoll }</td>
                                      <td>
                                        <strong>{ student?.stdName }</strong>
                                      </td>

                                      {/* for sa */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.sa }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.sa }
                                            id={ `inputId${ student?.classId }_sa` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "sa" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "sa" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for cs */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cs }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cs }
                                            id={ `inputId${ student?.classId }_cs` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "cs" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "cs" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for ctc */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.ctc }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.ctc }
                                            id={ `inputId${ student?.classId }_ctc` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "ctc" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "ctc" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for els */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.els }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.els }
                                            id={ `inputId${ student?.classId }_els` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "els" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "els" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for ct */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.ct }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.ct }
                                            id={ `inputId${ student?.classId }_ct` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "ct" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "ct" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for cat */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cat }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cat }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "cat" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "cat" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for psadec */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.psadec }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.psadec }
                                            id={ `inputId${ student?.classId }_psadec` }
                                            onBlur={ ( event ) =>
                                              checkMarks(
                                                event,
                                                student,
                                                "psadec"
                                              )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks(
                                                event,
                                                student,
                                                "psadec"
                                              )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for DMS */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.dms }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.dms }
                                            id={ `inputId${ student?.classId }_dms` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "dms" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "dms" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for CPS */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cps }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.cps }
                                            id={ `inputId${ student?.classId }_cps` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "cps" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "cps" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for AA */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.aa }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.aa }
                                            id={ `inputId${ student?.classId }_aa` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "aa" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "aa" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* for TPO */ }
                                      <td>
                                        { tabulation?.lockTabulation === 1 ? (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.tpo }
                                            disabled={ true }
                                          />
                                        ) : (
                                          <Input
                                            type="text"
                                            name="marks"
                                            //value={student?.marks}
                                            value={ student.marks.tpo }
                                            id={ `inputId${ student?.classId }_tpo` }
                                            onBlur={ ( event ) =>
                                              checkMarks( event, student, "tpo" )
                                            }
                                            onChange={ ( event ) =>
                                              checkMarks( event, student, "tpo" )
                                            }
                                          />
                                        ) }
                                      </td>

                                      {/* Add button */ }
                                      <td className="text-center">
                                        <button
                                          className={ `btn btn-sm ${ student.marks.upBy ? "btn-success" : "btn-outline-info"
                                            }` }
                                          onClick={ () => handleMarksChange( student ) }
                                          disabled={ tabulation?.lockTabulation === 1 } // Disable button when lockTabulation is 1
                                        >
                                          { student.marks.upBy ? "Update" : "Save" }
                                        </button>
                                      </td>


                                    </tr>
                                  );
                                } ) }
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </> )
                }


                <Modal
                  title="Behavioural Cognitive Outcomes [BCO]"
                  open={ showModel }
                  onOk={ hideModelFunction }
                  onCancel={ hideModelFunction }
                  footer={ null }>
                  <Row gutter={ [ 15 ] }>
                    <Col >
                      <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
                        <Text>Enter details for : <strong>{ selectStudent?.stdName }</strong>, Roll - <strong>{ selectStudent?.stdRoll }</strong></Text>
                      </div>
                    </Col>
                  </Row>

                  <Form
                    autoComplete="off"
                    layout="vertical"
                  >

                    <Col gutter={ [ 15 ] } className="mt-4">

                      {/* for sa */ }
                      <Form.Item label="Self Awareness">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.sa }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.sa }
                            id={ `inputId${ selectStudent?.classId }_sa` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "sa" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "sa" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for cs */ }
                      <Form.Item label="Communication Skill	">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cs }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cs }
                            id={ `inputId${ selectStudent?.classId }_cs` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "cs" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "cs" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for ctc */ }
                      <Form.Item label="Collaborative Thinking / Classification">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.ctc }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.ctc }
                            id={ `inputId${ selectStudent?.classId }_ctc` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "ctc" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "ctc" ) }
                          />
                        ) }
                      </Form.Item>


                      {/* for els */ }
                      <Form.Item label="Experimental Learning Skill">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.els }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.els }
                            id={ `inputId${ selectStudent?.classId }_els` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "els" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "els" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for ct */ }
                      <Form.Item label="Critical Thinking">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.ct }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.ct }
                            id={ `inputId${ selectStudent?.classId }_ct` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "ct" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "ct" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for cat */ }
                      <Form.Item label="Computational / Analytical Thinking">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cat }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cat }
                            id={ `inputId${ selectStudent?.classId }_cat` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "cat" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "cat" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for psadec */ }
                      <Form.Item label="Problem Solving Ability / Drawing Effective Conclusion">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.psadec }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.psadec }
                            id={ `inputId${ selectStudent?.classId }_psadec` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "psadec" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "psadec" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for DMS */ }
                      <Form.Item label="Decision Making Skill">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.dms }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.dms }
                            id={ `inputId${ selectStudent?.classId }_dms` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "dms" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "dms" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for CPS */ }
                      <Form.Item label="Creative Presentation Skill">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cps }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.cps }
                            id={ `inputId${ selectStudent?.classId }_cps` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "cps" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "cps" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for AA */ }
                      <Form.Item label="Aesthetic Appreciation">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.aa }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.aa }
                            id={ `inputId${ selectStudent?.classId }_aa` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "aa" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "aa" ) }
                          />
                        ) }
                      </Form.Item>



                      {/* for TPO */ }
                      <Form.Item label="Teacher's Perception (Overall)">
                        { tabulation?.lockTabulation === 1 ? (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.tpo }
                            disabled={ true }
                          />
                        ) : (
                          <Input
                            type="text"
                            name="marks"
                            value={ selectStudent?.marks?.tpo }
                            id={ `inputId${ selectStudent?.classId }_tpo` }
                            onBlur={ ( event ) => checkMarks( event, selectStudent, "tpo" ) }
                            onChange={ ( event ) => checkMarks( event, selectStudent, "tpo" ) }
                          />
                        ) }
                      </Form.Item>

                    </Col>
                  </Form>


                  <div className="panel-content mt-2 d-flex flex-row justify-content-between">
                    {/* Previous Button */ }
                    <Button
                      type="secondary"
                      onClick={ prevFun }
                      hidden={ selectStudentId == 0 }
                    >
                      &lt; Prev
                    </Button>

                    {/* Submit Button (Centered) */ }
                    { tabulation?.lockTabulation === 0 && (
                      <>
                        <Button
                          type="primary"
                          onClick={ handleMarksChangeModel }
                          hidden={ tabulation?.lockTabulation === 1 }
                        >
                          Submit & Next
                        </Button>
                      </>
                    ) }

                    {/* Next Button */ }
                    <Button
                      type="secondary"
                      onClick={ nextFun }
                      hidden={ selectStudentId == marksList.length - 1 }
                    >
                      Next &gt;
                    </Button>
                  </div>


                </Modal>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListBCO;
