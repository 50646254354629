import React, { useState, useEffect, useRef } from "react";

import { } from "antd";
import { Form, Row, Col, Modal, Card, Select, Table, Tag } from "antd";

import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import
{
  getSessionData,
  getUserData
} from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";


import dayjs from "dayjs"; // For date formatting

const { Option } = Select;

const StaffMyAttendance = () =>
{
  const [ state, setState ] = useState( {
    classSection: null,
    month_id: null
  } );

  // Get current month & year
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // Convert 0-based to 1-based
  const currentYear = today.getFullYear();
  const [ defaultMonth, setDefaultMonth ] = useState( `${ currentMonth }-${ currentYear }` );
  const [ defaultMonthF, setDefaultMonthF ] = useState( `${ currentMonth }-${ currentYear }` );
  const [ monthList, setMonthList ] = useState( [] );
  const [ staffAttendance, setStaffAttendance ] = useState( [] );
  const [ dateList, setDateList ] = useState( [] );
  const [ showList, setShowList ] = useState( false );
  const setSpinner = useOutletContext();
  const [ atData, setAtData ] = useState( [] );




  useEffect( () =>
  {
    getMonthList();

  }, [] );

  const handleSelectChange = ( field, value, month ) =>
  {
    setDefaultMonthF( month );
    setDefaultMonth( value );
    setState( { ...state, [ field ]: value } );
    getAttendentList( value );
  };


  const getMonthList = async () =>
  {
    setSpinner( true );
    const monthRes = await postRequest( "get-month-by-session", {
      sessionCode: getSessionData().rcode,
    } );

    let monthArr = monthRes.data.response.data;
    setMonthList( monthArr );
    setSpinner( false );

    // Find matching month from list
    const defaultMonthSet = monthArr.find(
      ( m ) => m.id === currentMonth && m.year === currentYear
    );

    setDefaultMonth( `${ defaultMonthSet.id }-${ defaultMonthSet.year }` );
    setDefaultMonthF( defaultMonthSet.month );

    if ( defaultMonth )
    {
      handleSelectChange( "month_id", `${ defaultMonthSet.id }-${ defaultMonthSet.year }`, defaultMonthSet.month );
    }

  };


  const getAttendentList = async ( month_id ) =>
  {
    if ( month_id !== null )
    {
      setSpinner( true );

      try
      {
        const response = await postRequest( "get-staff-personal-attendance", {
          sid: getSessionData().code,
          month: month_id.split( "-" )[ 0 ],
          mode: "mobile",
          year: month_id.split( "-" )[ 1 ],
        } );

        if ( response.data.error === 0 )
        {
          setStaffAttendance( response.data.response );

          const resData = response.data.response;
          generateAttendanceEvents( resData );

          setShowList( true );
        } else
        {
          ErrorNotificationMsg( response.data.errmsg );
        }
        setSpinner( false );
      } catch ( error )
      {
        ErrorNotificationMsg( "No records found." );
        setSpinner( false );
      }
    } else
    {
      ErrorNotificationMsg( "Please select all the fields" );
    }
  };

  // Function to ensure all dates in the month are included
  const completeMonthData = ( data, year, month ) =>
  {
    const startDate = dayjs( `${ year }-${ month }-01` );
    const daysInMonth = startDate.daysInMonth();

    let completeData = [];

    for ( let i = 1; i <= daysInMonth; i++ )
    {
      const formattedDate = startDate.date( i ).format( "YYYY-MM-DD" );
      const existingEntry = data.find( entry => entry.date === formattedDate );

      if ( existingEntry )
      {
        completeData.push( existingEntry ); // Keep existing record
      } else
      {
        completeData.push( { date: formattedDate, day: dayjs( formattedDate ).format( "dddd" ), status: "" } ); // Add missing date with no status
      }
    }

    return completeData;
  };


  const generateAttendanceEvents = ( resData ) =>
  {
    let thisEvents = [];

    // Process Present Days
    resData.present.forEach( ( date, index ) =>
    {
      thisEvents.push( {
        date: date,
        day: dayjs( date ).format( "dddd" ), // Convert to full day name
        status: "Present", // Default status (can be changed)
      } );
    } );

    // Process Absent Days
    resData.absent.forEach( ( date, index ) =>
    {
      thisEvents.push( {
        date: date,
        day: dayjs( date ).format( "dddd" ), // Convert to full day name
        status: "Absent", // Default status (can be changed)
      } );
    } );

    // Process Late Entry Days
    resData.late.forEach( ( date, index ) =>
    {
      thisEvents.push( {
        date: date,
        day: dayjs( date ).format( "dddd" ), // Convert to full day name
        status: "Late Entry", // Default status (can be changed)
      } );
    } );

    // Process Holidays
    resData.holiday.holidays.forEach( ( date, index ) =>
    {
      thisEvents.push( {
        date: date,
        day: dayjs( date ).format( "dddd" ), // Convert to full day name
        status: "Holiday", // Default status (can be changed)
      } );
    } );

    // Extract Year and Month from the first data entry
    const extractYearMonth = ( data ) =>
    {
      if ( data.length === 0 ) return { year: "", month: "" };

      const firstDate = dayjs( data[ 0 ].date );
      return {
        year: firstDate.format( "YYYY" ),  // Extract year
        month: firstDate.format( "MM" )    // Extract month (01, 02, ..., 12)
      };
    };

    // Get extracted year and month
    const { year, month } = extractYearMonth( thisEvents );

    // Run function to get complete month data
    const completedAttendanceData = completeMonthData( thisEvents, year, month );


    // Sorting the data by date
    const sortedData = completedAttendanceData.sort( ( a, b ) => new Date( a.date ) - new Date( b.date ) );
    console.log( sortedData );

    setDateList( sortedData );

  };


  // Show hideModelFunction
  const [ showModel, setShowModel ] = useState( false );
  const hideModelFunction = () =>
  {
    setShowModel( false );
  };

  const showModelFunction = () =>
  {
    setShowModel( true );

  };




  const [ weeksData, setWeeksData ] = useState( [] );

  // Arrange data into weeks (Sunday → Saturday)
  const arrangeWeeks = ( dates ) =>
  {
    let weeks = [];
    let currentWeek = new Array( 7 ).fill( null ); // 7 days in a week

    dates.forEach( ( item ) =>
    {
      const dayIndex = dayjs( item.date ).day(); // Sunday = 0, Monday = 1, etc.
      currentWeek[ dayIndex ] = item;

      if ( dayIndex === 6 )
      {
        weeks.push( [ ...currentWeek ] );
        currentWeek = new Array( 7 ).fill( null );
      }
    } );

    if ( currentWeek.some( ( day ) => day !== null ) )
    {
      weeks.push( currentWeek );
    }

    return weeks;
  };

  useEffect( () =>
  {
    const monthDates = dateList;
    console.log( 'monthDates', monthDates );
    const weeks = arrangeWeeks( monthDates );
    console.log( weeks );

    setWeeksData( weeks );
  }, [ dateList ] );

  // Handle cell click event
  const handleCellClick = async ( record ) =>
  {
    if ( !record ) return;
    // message.success( `Clicked Date: ${ record.date }` );
    console.log( `📅 Clicked Date: ${ record.date }` );


    // Find matching month from list
    const currentMonthData = monthList.find(
      ( m ) => m.month === defaultMonthF
    );


    setSpinner( true );

    // get-student-attendance-punch-details
    const response = await postRequest( "get-student-attendance-punch-details", {
      monthId: currentMonthData.id,
      attendanceDate: record.date,
      userType: 2, // 1 - Student, 2-Staff,
      referenceId: getUserData().referenceId
    } );


    if ( response.data.error === 0 )
    {
      setAtData( response.data.response );
      showModelFunction();
    }

    setSpinner( false );

  };

  const [ modalImage, setModalImage ] = useState( null );
  const [ isModalVisible, setIsModalVisible ] = useState( false );

  // Function to handle image click
  const handleImageClick = ( imageUrl ) =>
  {
    setModalImage( imageUrl );
    setIsModalVisible( true );
  };


  // Table columns
  const columns = [
    {
      title: "Date",
      dataIndex: "punchAt",
      key: "punchAt",
      width: "20%",
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
      width: "30%",
    },
    {
      title: "Image",
      dataIndex: "photo",
      key: "photo",
      width: "50%",
      render: ( text ) =>
        text === "" ? (
          <p>N/A</p>
        ) : (
          <img
            src={ text }
            alt="Demo"
            style={ { width: "60px", borderRadius: "8px", cursor: "pointer" } }
            onClick={ () => handleImageClick( text ) }
          />
        ),
    },
  ];

  return (
    <div id="content">
      <PageHeader
        pageTitle="My Attendance"
        pageIcon={ <i className='subheader-icon fal fa-hand-point-up'></i> }
      />


      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>My Attendance</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">

                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={ [ 15 ] } className="mb-3">
                    <Col xs={ 24 } sm={ 8 } lg={ 8 }>
                      <Form.Item
                        name="month_id"
                        label="Select Month"
                        rules={ [
                          {
                            required: true,
                            message: "Please select month!",
                          },
                        ] }
                      >

                        <p style={ { display: "none" } }>Set Default Text</p>

                        <Select
                          placeholder="Select Month"
                          value={ defaultMonth }
                          onChange={ ( value ) =>
                          {
                            const selectedMonth = monthList.find( ( m ) => `${ m.id }-${ m.year }` === value )?.month;
                            handleSelectChange( "month_id", value, selectedMonth );
                          } }
                        >
                          { monthList?.map( ( m, k ) => (
                            <Option key={ k } value={ `${ m.id }-${ m.year }` }>
                              { m.month }
                            </Option>
                          ) ) }
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="col-md-12">
                  { showList && (
                    <div className="row">


                      <div style={ { width: "100%", maxWidth: "1200px", margin: "auto", padding: "20px" } }>
                        <Card
                          style={ {
                            borderRadius: "10px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#ffffff",
                          } }
                        >

                          <h2 style={ { textAlign: "center", marginBottom: "20px" } }> { defaultMonthF }</h2>
                          <div style={ { overflowX: "auto", maxWidth: "100%" } }>
                            <Table
                              bordered
                              pagination={ false }
                              dataSource={ weeksData }
                              columns={ [
                                { title: "Sunday", dataIndex: "0", key: "sunday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Monday", dataIndex: "1", key: "monday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Tuesday", dataIndex: "2", key: "tuesday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Wednesday", dataIndex: "3", key: "wednesday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Thursday", dataIndex: "4", key: "thursday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Friday", dataIndex: "5", key: "friday", render: ( record ) => renderCell( record, handleCellClick ) },
                                { title: "Saturday", dataIndex: "6", key: "saturday", render: ( record ) => renderCell( record, handleCellClick ) },
                              ] }
                              rowKey={ ( record, index ) => index }
                              style={ { minWidth: "600px" } } // Ensures minimum width for better mobile viewing
                            />
                          </div>
                        </Card>
                      </div>


                      <div style={ { width: "100%", maxWidth: "1200px", margin: "auto", padding: "20px" } }>
                        <Card
                          style={ {
                            borderRadius: "10px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#ffffff",
                          } }
                        >
                          <h2 style={ { textAlign: "center", marginBottom: "20px" } }>{ `Total Status for ${ defaultMonthF }` }</h2>

                          <div className="col-md-12">
                            <div
                              className="row justify-content-center" // Center the row
                              style={ { display: "flex", justifyContent: "center", flexWrap: "wrap" } }
                            >
                              <div className="alert alert-success fade show px-3 py-2 m-2">
                                Present - <strong>{ staffAttendance?.present?.length || 0 }</strong>
                              </div>
                              <div className="alert alert-primary fade show px-3 py-2 m-2">
                                Late - <strong>{ staffAttendance?.late?.length || 0 }</strong>
                              </div>
                              <div className="alert alert-danger fade show px-3 py-2 m-2">
                                Absent - <strong>{ staffAttendance?.absent?.length || 0 }</strong>
                              </div>
                              <div className="alert alert-warning fade show px-3 py-2 m-2">
                                Holiday - <strong>{ staffAttendance?.holiday?.holidays?.length || 0 }</strong>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>



                      <div style={ { width: "100%", maxWidth: "1200px", margin: "auto", padding: "20px" } }>
                        <Card
                          style={ {
                            borderRadius: "10px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#ffffff",
                          } }
                        >
                          <h2 style={ { textAlign: "center", marginBottom: "20px" } }>{ `Total Status for Current Session (Till Today)` }</h2>

                          <div className="col-md-12">
                            <div
                              className="row justify-content-center" // Center the row
                              style={ { display: "flex", justifyContent: "center", flexWrap: "wrap" } }
                            >
                              <div className="alert alert-danger fade show px-3 py-2 mr-2">
                                Total Classes Held - <strong>{ staffAttendance.session_total.classes_held }</strong>
                              </div>
                              <div className="alert alert-success fade show px-3 py-2 mr-2">
                                No. of Days Present - <strong>{ staffAttendance.session_total.present }</strong>
                              </div>
                              <div className="alert alert-warning fade show px-3 py-2">
                                Overall Percentage - <strong>{ staffAttendance.session_total.overall_percent }%</strong>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>


                    </div>
                  ) }
                </div>


                <Modal
                  title={ `Attendance of ${ atData.nameDetails }` }
                  open={ showModel }
                  onOk={ hideModelFunction }
                  onCancel={ hideModelFunction }
                  footer={ null }
                  width="90vw" // Makes modal width responsive
                  className="responsive-modal"
                >
                  <div className="table-container">
                    <Table
                      columns={ columns }
                      dataSource={ atData.punchDetails }
                      pagination={ false } // Disable pagination for a small table
                      bordered
                      className="responsive-table"
                    />
                  </div>

                  {/* Inline CSS for responsiveness */ }
                  <style>
                    { `
      /* Modal container styling */
      .responsive-modal .ant-modal-content {
        padding: 20px;
        border-radius: 12px;
      }

      /* Table wrapper to enable horizontal scrolling on small screens */
      .table-container {
        width: 100%;
        overflow-x: auto; /* Enables horizontal scrolling */
      }

      /* Responsive table styling */
      .responsive-table {
        min-width: 600px; /* Prevents table from collapsing on small screens */
      }

      /* Make modal title responsive */
      .responsive-modal .ant-modal-title {
        font-size: 18px;
        text-align: center;
      }

      /* Adjust modal width for different screen sizes */
      @media (max-width: 768px) {
        .responsive-modal .ant-modal {
          width: 95vw !important; /* Expand width on mobile */
        }
        .responsive-table {
          min-width: 100%;
        }
      }
    `}
                  </style>
                </Modal>

                {/* Ant Design Modal for Image Preview */ }
                <Modal
                  open={ isModalVisible }
                  footer={ null }
                  onCancel={ () => setIsModalVisible( false ) }
                  centered
                  className="image-modal"
                >
                  <img src={ modalImage } alt="Full Preview" className="modal-image" />
                </Modal>
                {/* Inline CSS for styling */ }
                <style>
                  { `
          /* Small preview images inside the table */
          .table-image {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            cursor: pointer;
            object-fit: cover;
            transition: transform 0.2s ease-in-out;
          }
          .table-image:hover {
            transform: scale(1.1);
          }

          /* Modal image styling */
          .modal-image {
            width: 100%;
            max-width: 90vw;
            max-height: 80vh;
            border-radius: 10px;
            object-fit: contain;
          }

          /* Modal customization */
          .image-modal .ant-modal-content {
            padding: 10px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          }

          /* Modal close button styling */
          .image-modal .ant-modal-close {
            font-size: 18px;
            color: #666;
          }
          .image-modal .ant-modal-close:hover {
            color: #ff4d4f;
          }
        `}
                </style>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};



// Render cell with status
const renderCell = ( record, handleClick ) =>
{
  if ( !record ) return "-"; // Empty cell for missing days
  return (
    <div
      style={ { textAlign: "center", cursor: "pointer", padding: "2px" } }
      onClick={ () => handleClick( record ) }
    >
      <strong>
        { record.status === "Absent" ? (
          <span className="badge badge-pill badge-danger">{ dayjs( record.date ).date() }</span>
        ) : record.status === "Late Entry" ? (
          <span className="badge badge-pill badge-primary">{ dayjs( record.date ).date() }</span>
        ) : record.status === "Present" ? (
          <span className="badge badge-pill badge-success">{ dayjs( record.date ).date() }</span>
        ) : record.status === "Holiday" ? (
          <span className="badge badge-pill badge-warning">{ dayjs( record.date ).date() }</span>
        ) : (
          dayjs( record.date ).date() // Default case: Only print date
        ) }
      </strong>
      <br />
    </div>
  );
};


export default StaffMyAttendance;