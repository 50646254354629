import React, { useState, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import moment from "moment";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";


import
{
  getSessionData,
  getSchoolData,
  getUserType,
} from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import { useRef, useCallback } from "react";
import { Button, Slider, message, Card, Space } from "antd"
import Upload from "antd/lib/upload"
import { UploadOutlined } from "@ant-design/icons"
import "react-image-crop/dist/ReactCrop.css"
import "antd/dist/reset.css"



const ParentsPicture = ( props ) =>
{



  const [ fileList, setFileList ] = useState( [] )
  const [ imageUrl, setImageUrl ] = useState( "" )
  const [ crop, setCrop ] = useState( {
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 1,
  } )
  const [ completedCrop, setCompletedCrop ] = useState( null )
  const [ aspectRatio, setAspectRatio ] = useState( 1 )
  const imgRef = useRef( null )
  const previewCanvasRef = useRef( null )

  const beforeUpload = ( file ) =>
  {
    const isImage = file.type.startsWith( "image/" )
    if ( !isImage )
    {
      message.error( "You can only upload image files!" )
      return false
    }
    return false
  }

  const handleChange = ( info ) =>
  {
    // Clear previous image
    if ( info.fileList.length === 0 )
    {
      setImageUrl( "" )
      setFileList( [] )
      return
    }

    // Only keep the latest file
    const latestFile = info.fileList[ info.fileList.length - 1 ]
    setFileList( [ latestFile ] )

    // Read the file and set the image URL
    const reader = new FileReader()
    reader.addEventListener( "load", () =>
    {
      setImageUrl( reader.result )
    } )
    reader.readAsDataURL( latestFile.originFileObj )
  }

  const handleAspectRatioChange = ( value ) =>
  {
    setAspectRatio( value )
    setCrop( ( prev ) => ( {
      ...prev,
      aspect: value,
    } ) )
  }

  const generateCroppedImage = () =>
  {
    if ( !imgRef.current || !completedCrop || !previewCanvasRef.current )
    {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext( "2d" )

    if ( !ctx )
    {
      return
    }

    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * scaleX * pixelRatio
    canvas.height = crop.height * scaleY * pixelRatio

    ctx.scale( pixelRatio, pixelRatio )
    ctx.imageSmoothingQuality = "high"

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
    const cropWidth = crop.width * scaleX
    const cropHeight = crop.height * scaleY

    ctx.drawImage( image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight )

    message.success( "Image cropped successfully!" )
  }

  const downloadCroppedImage = () =>
  {
    if ( !previewCanvasRef.current )
    {
      message.error( "Please crop the image first!" )
      return
    }

    const canvas = previewCanvasRef.current
    const link = document.createElement( "a" )
    link.download = "cropped-image.png"
    link.href = canvas.toDataURL( "image/png" )
    link.click()
  }




  return (
    <div id="content">
      <PageHeader
        pageTitle="Homework"
        pageIcon={ <i className="subheader-icon fal fa-book-reader"></i> }
      />
      {/* <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Homework</h2>
              <div className="panel-toolbar">
                { getUserType() === "staff" && (
                  <Link
                    to="/create-home-work"
                    className="btn btn-sm btn-primary waves-effect waves-themed mr-2"
                  >
                    <i className="fal fa-plus"></i> Create Homework
                  </Link>
                ) }

                <HomeWorkFilter
                  filterData={ filterData }
                  handleFilterChangeFilterDate={ handleFilterChangeFilterDate }
                  handleFilterSelectChange={ handleFilterSelectChange }
                  handleFilterChangeDateType={ handleFilterChangeDateType }
                  applyFilter={ applyFilter }
                  filterApply={ filterApply }
                />
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                { homeWorkList &&
                  homeWorkList.map( ( homeWork ) =>
                  {
                    return (
                      <div className="card border mb-2" key={ homeWork.id }>
                        <div className="card-body">
                          <img
                            src={ homeWork?.created_by_image }
                            alt="created-by-img"
                            className="profile-image rounded-circle"
                            onError={ ( e ) =>
                            {
                              e.target.onerror = null;
                              e.target.src = userIcon;
                            } }
                          />

                          <span className="badge card-title">
                            <strong> { homeWork?.subject }</strong>
                          </span>
                          <br />
                          { getUserType() === "staff" && (
                            <span className="badge badge-primary">
                              { homeWork?.class_code }
                            </span>
                          ) }
                          <span className="d-block">
                            <strong>{ homeWork?.topic }</strong>
                          </span>

                          <div className="frame-wrap mb-2">
                            <span className="d-block text-muted">
                              Posted By : { homeWork?.created_by }
                            </span>
                            { getUserType() === "staff" ? (
                              homeWork?.approved ? (
                                <span className="d-block text-muted">
                                  Approved By : { homeWork?.approve_by }
                                </span>
                              ) : (
                                homeWork?.is_draft ? (
                                  <span className="badge badge-warning badge-pill">
                                    DRAFT
                                  </span>
                                ) : (
                                  <span className="badge border border-danger text-danger badge-pill">
                                    NOT APPROVED
                                  </span>
                                )
                              )
                            ) : (
                              ""
                            ) }

                            <span className="d-block text-muted">
                              <i className="fal fa-lg fa-angle-double-right text-warning"></i>&nbsp;
                              Assigned On : { homeWork?.assignment_date }
                            </span>
                            <span className="d-block text-muted">
                              <i className="fal fa-lg fa-angle-double-right text-warning"></i>&nbsp;
                              Submit By : { homeWork?.submission_date }
                            </span>
                          </div>

                          <HomeWorkDetail
                            homeWorkDetail={ homeWork }
                            history={ props.history }
                          />
                        </div>
                        <div className="card-footer text-muted py-2">
                          <HomeWorkLikeList
                            homeWorkDetail={ homeWork }
                            hideParent={ false }
                          />
                          { homeWork.comment_enable ? (
                            <span className="text-primary mr-2 pointer">
                              { getUserType() === "staff"
                                ? homeWork.comment_count
                                : "" }
                              &nbsp;
                              { getUserType() === "staff" ? (
                                <i
                                  className={
                                    homeWork.comment_count > 0
                                      ? "fas fa-comment"
                                      : "fal fa-comment"
                                  }
                                ></i>
                              ) : (
                                <i
                                  className={
                                    homeWork.is_user_comment
                                      ? "fas fa-comment"
                                      : "fal fa-comment"
                                  }
                                ></i>
                              ) }
                            </span>
                          ) : (
                            ""
                          ) }
                          <span className="text-primary mr-2">
                            { getUserType() === "staff"
                              ? homeWork.documents_count
                              : "" }
                            &nbsp;
                            <i
                              className={
                                homeWork.documents_count > 0
                                  ? "fas fa-paperclip"
                                  : "fal fa-paperclip"
                              }
                            ></i>
                          </span>{ " " }
                        </div>
                      </div>
                    );
                  } ) }

                { homeWorkList && homeWorkList.length === 0 && (
                  <div className="alert alert-warning ">
                    No Home Work List Found!
                  </div>
                ) }

                { homeWorkList && homeWorkList.length > 0 && (
                  <div>
                    <div className="dataTables_wrapper mt-3">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="dataTables_info">
                            Showing{ " " }
                            { paginationData.current === 1
                              ? "1"
                              : ( paginationData.current - 1 ) * 10 + 1 }{ " " }
                            to{ " " }
                            { paginationData.current *
                              paginationData.record_per_page >
                              paginationData.total_record
                              ? paginationData.total_record
                              : paginationData.current *
                              paginationData.record_per_page }{ " " }
                            of { paginationData.total_record } entries
                          </div>
                        </div>
                        <div className="col-md-7 right">
                          <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                              <li
                                className={
                                  paginationData.prev === ""
                                    ? "paginate_button page-item previous disabled"
                                    : "paginate_button page-item previous"
                                }
                              >
                                <a
                                  onClick={ handlePrevPage }
                                  className="page-link"
                                >
                                  <i className="fal fa-chevron-left"></i>
                                </a>
                              </li>
                              <li
                                className={
                                  paginationData.next === ""
                                    ? "paginate_button page-item next disabled"
                                    : "paginate_button page-item next"
                                }
                              >
                                <a
                                  onClick={ handleNextPage }
                                  className="page-link"
                                >
                                  <i className="fal fa-chevron-right"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) }
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="p-8 max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-6">Image Cropper</h1>

        <Card title="Upload Image" className="mb-6">
          <Upload listType="picture" fileList={ fileList } beforeUpload={ beforeUpload } onChange={ handleChange } maxCount={ 1 }>
            <Button icon={ <UploadOutlined /> }>Click to Upload</Button>
          </Upload>
        </Card>

        { imageUrl && (
          <>
            <Card title="Crop Image" className="mb-6">
              <div className="mb-4">
                <p className="mb-2">Aspect Ratio: { aspectRatio }:1</p>
                <Slider
                  min={ 0.5 }
                  max={ 2 }
                  step={ 0.1 }
                  value={ aspectRatio }
                  onChange={ handleAspectRatioChange }
                  tooltip={ { formatter: ( value ) => `${ value }:1` } }
                />
              </div>

              <div className="overflow-auto">
                <ReactCrop
                  crop={ crop }
                  onChange={ ( c ) => setCrop( c ) }
                  onComplete={ ( c ) => setCompletedCrop( c ) }
                  aspect={ aspectRatio }
                >
                  <img ref={ imgRef } src={ imageUrl || "/placeholder.svg" } alt="Upload" className="max-w-full" />
                </ReactCrop>
              </div>

              <Space className="mt-4">
                <Button type="primary" onClick={ generateCroppedImage }>
                  Crop Image
                </Button>
                <Button onClick={ downloadCroppedImage }>Download Cropped Image</Button>
              </Space>
            </Card>

            <Card title="Preview">
              <div className="flex justify-center">
                <canvas
                  ref={ previewCanvasRef }
                  className="max-w-full border border-gray-300"
                  style={ {
                    display: completedCrop ? "block" : "none",
                    objectFit: "contain",
                  } }
                />
                { !completedCrop && <div className="text-center p-8 text-gray-500">Crop the image to see preview</div> }
              </div>
            </Card>
          </>
        ) }
      </div>



    </div>
  );
};

export default ParentsPicture;
