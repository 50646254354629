import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import MainRoutes from "./components/common/MainRoutes";
import 'react-image-crop/dist/ReactCrop.css';


import { notification } from "antd";
import { setNotificationApi } from "./utils/NotificationHelper";

function App ()
{

  const [ api, contextHolder ] = notification.useNotification();

  useEffect( () =>
  {
    setNotificationApi( api ); // Set message API instance globally
  }, [ api ] );

  return (
    <Router>
      { contextHolder } {/* Ensure contextHolder is at the root */ }
      <MainRoutes />
    </Router>
  );
}

export default App;


