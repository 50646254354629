import { notification } from "antd";

let notificationApi;

// Function to set messageApi instance from App.js
export const setNotificationApi = ( api ) =>
{
  notificationApi = api;
};

export const SuccessNotificationMsg = ( message = "", description = "" ) =>
{

  if ( notificationApi )
  {
    notificationApi[ 'success' ]( {
      message,
      description,
      placement: "topRight",
      duration: 2, // Auto close in 2 seconds
    } );
  }
};

export const ErrorNotificationMsg = ( message = "", description = "" ) =>
{

  if ( notificationApi )
  {
    notificationApi[ 'error' ]( {
      message,
      description,
      placement: "topRight",
      duration: 2, // Auto close in 2 seconds
    } );
  }

};
