import React, { useRef, useState } from "react";
import { Modal, Form, Button, Select } from "antd";
import { getSchoolData, getSessionData } from "../../utils/Helpers";
import { postRequest } from "../../axios";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { Option } from "antd/lib/mentions";
import { useOutletContext } from "react-router-dom";

const TabulationActivitiesModal = (props) => {
  const formRef = useRef();
  const [gradingList, setGradingList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  const getStudentActivityList = async () => {
    setSpinner(true);
    const response = await postRequest("exam-activity-tabulation-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      cid: props.cid,
      examId: props.examId
    });

    setSpinner(false);
    if (response.data.response === "") {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setGradingList(response.data.response.data.gradingArray);
      setActivityList(response.data.response.data.activityArray);
    }
  };

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getStudentActivityList();
  }

  const handleChange = (value, item) => {
    let acList = [...activityList];

    acList.map((acl) => {
      let aclItems = acl.items;

      let index = aclItems.findIndex(
        (res) => res.id === item.id
      );
      let act = { ...aclItems[index] }
      act.value = value;
      aclItems[index] = act;
    })

    setActivityList(acList);
  }

  const onFinish = async () => {
    setBtnLoading(true);
    setSpinner(true);

    const payload = {
      schoolCode: getSchoolData().school_code,
      cid: props.cid,
      examId: props.examId,
      form_data: {
        activityArray: activityList
      }
    }

    try {
      const res = await postRequest("exam-activity-tabulation-update", payload);

      setSpinner(false);
      if (res.data.error === 0) {
        SuccessNotificationMsg("Success", "Acivity successfully updated.");
        setBtnLoading(false);
        setShowModel(false);
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Sorry!! Unable to update activity");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  }

  return (
    <>
      <button
        className="btn btn-sm btn-success"
        onClick={() => showModelFunction()}
      >
        EDIT
      </button>

      <Modal
        title={"Tabulation Activities of " + props.studentName}
        open={showModel}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form onFinish={onFinish} ref={formRef} autoComplete="off" layout="vertical">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {!!activityList &&
                  activityList.map((activity, id) => {
                    return (
                      <table key={id} className="table table-sm table-bordered table-hover">
                        <thead className="thead-themed">
                          <tr className="text-center">
                            <th>{activity?.heading}</th>
                            <th>MARKS / GRADE / SCALE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!activity.items &&
                            activity.items.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item?.label}</td>
                                  <td>
                                    <Select
                                      allowClear
                                      name="grade"
                                      placeholder="Select Marks/Grade/Scale"
                                      value={item?.value}
                                      onChange={(event) =>
                                        handleChange(event, item)
                                      }
                                    >
                                      {!!gradingList &&
                                        gradingList.map((gd, g) => (
                                          <Option key={g+gd.value} value={gd.id}>
                                            {gd.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="panel-content mt-2 d-flex flex-row">
            <Button
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              className="btn btn-primary ml-auto waves-effect waves-themed"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TabulationActivitiesModal;