import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Space, Modal, Row, Col, Select, Typography } from "antd";
import { postRequest } from "../../axios";
import
  {
    getSessionData,
    getSchoolData,
    getUserData,
  } from "../../utils/Helpers";

import
  {
    ErrorNotificationMsg,
    SuccessNotificationMsg,
  } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";



const { Option } = Select;
const { Title, Text } = Typography;


const StudentListDPLS = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();
  const isMobile = /Mobi|Android|iPhone/i.test( navigator.userAgent ) || window.innerWidth < 768;


  const marksKeys = [ "ls", "cs", "es", "cos", "csk", "fs", "cspss", "scs", "dms", "is", "os", "ecs", "rs", "aspa", "l" ];
  const skillsMap = {
    ls: "Listening Skill",
    cs: "Communication Skill",
    es: "Empathy Skill",
    cos: "Co-operation Skill",
    csk: "Conversation Skill",
    fs: "Friendship Skill",
    cspss: "Conflict Resolution / Problem Solving Skill",
    scs: "Stress Coping Skill",
    dms: "Decision Making Skill",
    is: "Interpersonal Skill",
    os: "Organization Skill",
    ecs: "Emotion Control Skill",
    rs: "Respective Skill",
    aspa: "Assertiveness Skill / Positive Attitude",
    l: "Leadership"
  }
  const [ showModel, setShowModel ] = useState( false );
  const [ selectStudentId, setSelectStudentId ] = useState( 0 );
  const [ selectStudent, setSelectStudent ] = useState( {} );

  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-dpls-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    console.log( "Checking marks" );
    console.log( event );

    let inputValue = event.target.value.toUpperCase(); // Convert to uppercase
    console.log( inputValue );

    // Allow only A, B, C, D, or blank
    if ( ![ "A", "B", "C", "D", "" ].includes( inputValue ) )
    {
      event.target.value = "";
      inputValue = "";
      ErrorNotificationMsg( "Grade should be  A or B or C or D " );
    }

    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };

    items[ documentIndex ] = item;
    item.marks[ indicator ] = inputValue;

    setMarksList( items );
  };

  const handleMarksChange = async ( student ) =>
  {

    const validKeys = [ "ls", "cs", "es", "cos", "csk", "fs", "cspss", "scs", "dms", "is", "os", "ecs", "rs", "aspa", "l" ]; // Keys to check
    const skillsMap = {
      ls: "Listening Skill",
      cs: "Communication Skill",
      es: "Empathy Skill",
      cos: "Co-operation Skill",
      csk: "Conversation Skill",
      fs: "Friendship Skill",
      cspss: "Conflict Resolution / Problem Solving Skill",
      scs: "Stress Coping Skill",
      dms: "Decision Making Skill",
      is: "Interpersonal Skill",
      os: "Organization Skill",
      ecs: "Emotion Control Skill",
      rs: "Respective Skill",
      aspa: "Assertiveness Skill / Positive Attitude",
      l: "Leadership"
    }


    const isValid = validKeys.every(
      ( key ) => student.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( student.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      form_data: {
        marks: [
          {
            classId: student.classId,
            ls: student.marks.ls,
            cs: student.marks.cs,
            es: student.marks.es,
            cos: student.marks.cos,
            csk: student.marks.csk,
            fs: student.marks.fs,
            cspss: student.marks.cspss,
            scs: student.marks.scs,
            dms: student.marks.dms,
            is: student.marks.is,
            os: student.marks.os,
            ecs: student.marks.ecs,
            rs: student.marks.rs,
            aspa: student.marks.aspa,
            l: student.marks.l,


          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-dpls-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Marks saved successfully." );
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };



  const handleMarksChangeModel = async () =>
  {

    const validKeys = [ "ls", "cs", "es", "cos", "csk", "fs", "cspss", "scs", "dms", "is", "os", "ecs", "rs", "aspa", "l" ]; // Keys to check

    const skillsMap = {
      ls: "Listening Skill",
      cs: "Communication Skill",
      es: "Empathy Skill",
      cos: "Co-operation Skill",
      csk: "Conversation Skill",
      fs: "Friendship Skill",
      cspss: "Conflict Resolution / Problem Solving Skill",
      scs: "Stress Coping Skill",
      dms: "Decision Making Skill",
      is: "Interpersonal Skill",
      os: "Organization Skill",
      ecs: "Emotion Control Skill",
      rs: "Respective Skill",
      aspa: "Assertiveness Skill / Positive Attitude",
      l: "Leadership"
    }


    const isValid = validKeys.every(
      ( key ) => selectStudent.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( selectStudent.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      form_data: {
        marks: [
          {
            classId: selectStudent.classId,
            ls: selectStudent.marks.ls,
            cs: selectStudent.marks.cs,
            es: selectStudent.marks.es,
            cos: selectStudent.marks.cos,
            csk: selectStudent.marks.csk,
            fs: selectStudent.marks.fs,
            cspss: selectStudent.marks.cspss,
            scs: selectStudent.marks.scs,
            dms: selectStudent.marks.dms,
            is: selectStudent.marks.is,
            os: selectStudent.marks.os,
            ecs: selectStudent.marks.ecs,
            rs: selectStudent.marks.rs,
            aspa: selectStudent.marks.aspa,
            l: selectStudent.marks.l
          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-dpls-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Marks saved successfully." );
        nextFun();
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };




  const hideModelFunction = () =>
  {

    setSelectStudent( {} );
    setSelectStudentId( 0 );
    setShowModel( false );
  };

  const prevFun = () =>
  {
    console.log( "11" );
    // if selectStudentId ==  0
    if ( selectStudentId == 0 )
    {
      ErrorNotificationMsg( "Please click on Next" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );

    // set student index -1
    const newId = selectStudentId - 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );

    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to previous student" );

  };

  const nextFun = () =>
  {
    // if selectStudentId == length of marks - 1
    if ( selectStudentId == marksList.length - 1 )
    {
      ErrorNotificationMsg( "Please click on Previous" );
      return;
    }
    setSelectStudent( {} );
    setShowModel( false );
    // set student index +1
    const newId = selectStudentId + 1;
    setSelectStudentId( newId );
    // set setSelectStudent using id
    setSelectStudent( marksList[ newId ] );
    setShowModel( true );
    // SuccessNotificationMsg( "Successfully going to next student" );
  };

  const showModelFunction = ( indexId, student ) =>
  {
    setSelectStudent( student );
    setSelectStudentId( indexId );

    setShowModel( true );

  };




  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr d-flex justify-content-between align-items-center">
              <h2 className="m-0">Student List of Class: { props.classSection }</h2>
              <div className="panel-toolbar">
                {
                  tabulation?.lockTabulation === 0 && (
                    <>
                      <Button
                        type="success"
                        onClick={ async () =>
                        {

                          setSpinner( true );

                          const responseNewList = await postRequest(
                            "exam-marks-tabulation-dpls-all-student",
                            {
                              sessionCode: getSessionData().rcode,
                              classSection: props.classSection,
                            }
                          );

                          if ( responseNewList.data.error > 0 )
                          {
                            ErrorNotificationMsg( responseNewList.data.errmsg );
                            setSpinner( false );
                            return;
                          }

                          setTabulation( responseNewList.data.response.data );
                          setMarksList( responseNewList.data.response.data.students );
                          const checkData = responseNewList.data.response.data.students;

                          let setLock = false;

                          for ( let i = 0; i < checkData.length; i++ )
                          {
                            if ( checkData[ i ].marks.ls == undefined || checkData[ i ].marks.ls == null || checkData[ i ].marks.ls === "" )
                            {
                              setLock = false;
                              break;
                            } else
                            {
                              setLock = true;
                            }
                          }

                          if ( setLock == false )
                          {
                            ErrorNotificationMsg( "Please fill in the all missing data." );
                            setSpinner( false );
                            return;
                          }


                          const response = await postRequest( "exam-marks-finalize", {
                            // schoolCode: getSchoolData().school_code,
                            sessionCode: getSessionData().rcode,
                            classSection: props.classSection,
                            // term: props.examId,
                            // subjectId: props.subjectId,
                            // userId: getUserData().tid,
                            examType: "dpls",
                          } );

                          setSpinner( false );
                          if ( response.data.error > 0 )
                          {
                            ErrorNotificationMsg( response.data.errmsg );
                          }


                          getTabulation();
                        } }
                        className="btn btn-danger waves-effect waves-themed m-2"
                      >
                        Finalize
                      </Button>
                    </>
                  )
                }
              </div>
            </div>


            <div className="panel-container show">
              <div className="panel-content">

                <div className="form-row">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-danger fade show px-3 py-2">
                      <strong>A</strong> - Very Good, <strong>B</strong> - Good, <strong>C</strong> - Satisfactory, <strong>D</strong> - Requires More Development
                    </div>
                  </div>
                </div>
                {
                  isMobile
                    ? ( <>

                      <Form autoComplete="off" layout="vertical" ref={ formRef }>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                            <thead className="thead-themed">
                              <tr className="text-center">
                                <th>ROLL</th>
                                <th>NAME</th>
                                <th>Action</th>

                              </tr>
                            </thead>
                            <tbody>
                              { marksList &&
                                marksList.map( ( student, indexId ) =>
                                {
                                  return (
                                    <tr key={ student?.classId }>
                                      <td>{ student?.stdRoll }</td>
                                      <td>
                                        <strong>{ student?.stdName }</strong>
                                      </td>

                                      {/* Add button */ }
                                      <td className="text-center">
                                        <button
                                          className={ `btn btn-sm ${ student?.marks?.ls
                                            ? "btn-success"
                                            : "btn-outline-info"
                                            }` }
                                          onClick={ () => showModelFunction( indexId, student ) }
                                        >
                                          { student?.marks?.ls ? "Edit" : "Add" }
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                } ) }
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </> )
                    : ( <>
                      <Form autoComplete="off" layout="vertical" ref={ formRef }>

                        <div className="table-responsive">
                          <table className="table table-sm table-bordered table-hover">
                            <thead className="thead-themed">
                              <tr className="text-center">
                                <th>ROLL</th>
                                <th>NAME</th>
                                <th>LS</th>
                                <th>CS</th>
                                <th>ES</th>
                                <th>COS</th>
                                <th>CSK</th>
                                <th>FS</th>
                                <th>CSPSS</th>
                                <th>SCS</th>
                                <th>DMS</th>
                                <th>IS</th>
                                <th>OS</th>
                                <th>ECS</th>
                                <th>RS</th>
                                <th>ASPA</th>
                                <th>L</th>

                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              { marksList &&
                                marksList.map( ( student ) =>
                                {
                                  return (
                                    <tr key={ student?.classId }>
                                      <td>{ student?.stdRoll }</td>
                                      <td>
                                        <strong>{ student?.stdName }</strong>
                                      </td>


                                      { marksKeys.map( ( key ) => (
                                        <td key={ key }>
                                          { tabulation?.lockTabulation === 1 ? (
                                            <Input
                                              type="text"
                                              name="marks"
                                              value={ student.marks ? student.marks[ key ] : "" }
                                              disabled={ true }
                                            />
                                          ) : (
                                            <Input
                                              type="text"
                                              name="marks"
                                              value={ student.marks ? student.marks[ key ] : "" }
                                              id={ `inputId${ student?.classId }_${ key }` }
                                              onBlur={ ( event ) => checkMarks( event, student, key ) }
                                              onChange={ ( event ) => checkMarks( event, student, key ) }
                                            />
                                          ) }
                                        </td>
                                      ) ) }


                                      {/* Add button */ }
                                      <td className="text-center">
                                        <button
                                          className={ `btn btn-sm ${ student.marks.aspa ? "btn-success" : "btn-outline-info"
                                            }` }
                                          onClick={ () => handleMarksChange( student ) }
                                          disabled={ tabulation?.lockTabulation === 1 } // Disable button when lockTabulation is 1
                                        >
                                          { student.marks.aspa ? "Update" : "Save" }
                                        </button>
                                      </td>

                                    </tr>
                                  );
                                } ) }
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </> ) }


                <Modal
                  title="Learning Perspective of Cognitive Domain [BCO]"
                  open={ showModel }
                  onOk={ hideModelFunction }
                  onCancel={ hideModelFunction }
                  footer={ null }>
                  <Row gutter={ [ 15 ] }>
                    <Col span={ 24 }>
                      <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
                        <Text>Enter details for : <strong>{ selectStudent?.stdName }</strong>, Roll - <strong>{ selectStudent?.stdRoll }</strong></Text>
                      </div>
                    </Col>
                  </Row>

                  <Form
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Col gutter={ [ 15 ] } className="mt-4">
                      { marksKeys.map( ( key ) => (
                        <>

                          {/* Space 4 mt  */ }
                          {/* <div style={ { height: "5px" } } /> */ }

                          <Form.Item label={ skillsMap[ key ] }>

                            { tabulation?.lockTabulation === 1 ? (
                              <Input
                                type="text"
                                name="marks"
                                value={ selectStudent.marks ? selectStudent.marks[ key ] : "" }
                                disabled={ true }
                              />
                            ) : (
                              <Input
                                type="text"
                                name="marks"
                                value={ selectStudent.marks ? selectStudent.marks[ key ] : "" }
                                id={ `inputId${ selectStudent?.classId }_${ key }` }
                                onBlur={ ( event ) => checkMarks( event, selectStudent, key ) }
                                onChange={ ( event ) => checkMarks( event, selectStudent, key ) }
                              />
                            ) }
                          </Form.Item>
                        </>
                      ) ) }
                    </Col>
                  </Form>

                  <div className="panel-content mt-2 d-flex flex-row justify-content-between">
                    {/* Previous Button */ }
                    <Button
                      type="secondary"
                      onClick={ prevFun }
                      hidden={ selectStudentId == 0 }
                    >
                      &lt; Prev
                    </Button>


                    {/* Submit Button (Centered) */ }
                    { tabulation?.lockTabulation === 0 && (
                      <>
                        <Button
                          type="primary"
                          onClick={ handleMarksChangeModel }
                          hidden={ tabulation?.lockTabulation === 1 }
                        >
                          Submit & Next
                        </Button>
                      </>
                    ) }

                    {/* Next Button */ }
                    <Button
                      type="secondary"
                      onClick={ nextFun }
                      hidden={ selectStudentId == marksList.length - 1 }
                    >
                      Next &gt;
                    </Button>
                  </div>



                </Modal>


              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default StudentListDPLS;
