import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Form,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { Option } from "antd/lib/mentions";
import { useOutletContext } from "react-router-dom";

const StudentListResultRemarks = (props) => {
  const formRef = useRef();
  const [remarksList, setRemarksList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStudentList();
  }, []);

  const getStudentList = async () => {
    setSpinner(true);
    const response = await postRequest("exam-get-result-remarks-student-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId
    });

    if (response.data.response === "") {
      setSpinner(false);
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setStudentList(response.data.response.data);
    }

    const examRes = await postRequest("exam-get-result-remarks-list");
    if (examRes.data.error != 0) {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setRemarksList(examRes.data.response);
    }
    setSpinner(false);
  };

  const handleChange = (value, student) => {
    let items = [...studentList];
    let documentIndex = items.findIndex(
      (res) => res.id === student.id
    );
    let item = { ...items[documentIndex] };
    item.remarks = value;
    items[documentIndex] = item;

    setStudentList(items);
  };

  const onFinish = async () => {
    setBtnLoading(true);
    setSpinner(true);

    let resultRemarksArr = [];
    studentList.map((mInfo) => {
      resultRemarksArr.push({
        id: mInfo.id,
        remarks: mInfo.remarks
      });
    });

    const payload = {
      schoolCode: getSchoolData().school_code,
      form_data: {
        students: resultRemarksArr
      }
    };

    try {
      const res = await postRequest("exam-get-result-remarks-save", payload);

      if (res.data.error === 0) {
        SuccessNotificationMsg("Success", "Remarks successfully updated.");
        setBtnLoading(false);
        setSpinner(false);
        props.handleShowList(false);
      } else {
        setBtnLoading(false);
        setSpinner(false);
        ErrorNotificationMsg("Sorry!! Unable to update remarks.");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>RANK</th>
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>TOTAL MARKS</th>
                          <th>RESULT</th>
                          <th>REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!studentList &&
                          studentList.map((student, id) => {
                            return (
                              <tr key={student?.id}>
                                <td className="text-right">{id + 1}</td>
                                <td className="text-right">{student?.stdRoll}</td>
                                <td><strong>{student?.stdName}</strong></td>
                                <td className="text-right">{student?.totalMarks}</td>
                                <td className="text-center">{student?.result}</td>
                                <td>
                                  <Select
                                    allowClear
                                    name="remarks"
                                    placeholder="Select Remarks"
                                    value={student?.remarks}
                                    onChange={(event) =>
                                      handleChange(event, student)
                                    }
                                  >
                                    {!!remarksList &&
                                      remarksList.map((rm) => (
                                        <Option key={rm} value={rm}>
                                          {rm}
                                        </Option>
                                      ))}
                                  </Select>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={btnLoading}
                        className="btn btn-primary ml-auto waves-effect waves-themed"
                      >
                        Submit
                      </Button>
                    </Space>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListResultRemarks;