import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const LoginMobile = (props) => {
    const { token, dbtoken, userType } = useParams();
    const navigate = useNavigate();
    const setSpinner = useOutletContext();

    useEffect(() => {
        setSpinner(true);
        getLoginDashboard();
        setSpinner(false);
    }, []);

    const getLoginDashboard = async () => {
        localStorage.clear();
        localStorage.setItem("dbtoken", dbtoken);
        localStorage.setItem("restoken", token);
        localStorage.setItem("userType", userType);
        localStorage.setItem("loggedInFrom", 'androidApp');

        navigate("/login-dashboard");
    };

    return (
        <></>
    );
};

export default LoginMobile;