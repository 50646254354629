import React, { useState, useEffect, useRef } from "react";
import
{
  Form,
  Input,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListFormative = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest( "exam-marks-tabulation-formative-list", {
      // schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      // userId: getUserData().tid,
    } );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    let fm = {
      a: tabulation.fullMarks[ "fmA" ],
      b: tabulation.fullMarks[ "fmB" ],
      c: tabulation.fullMarks[ "fmC" ]
    };
    let mo = parseInt( event.target.value );
    if ( mo !== 111 && mo !== 222 && mo !== 333 )
    {
      if ( fm[ indicator ] < mo )
      {
        event.target.value = 0;
        ErrorNotificationMsg( "Marks can not be more than " + fm[ indicator ] );
      }

      handleMarksChange( event, student, indicator );

    }
  };

  const handleMarksChange = async ( event, student, indicator ) =>
  {
    let mo = event.target.value;
    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };


    items[ documentIndex ] = item;
    if ( indicator === "a" )
    {
      item.marks.moA = mo;
      student.marks.moA = mo;
    }
    else if ( indicator === "b" )
    {
      item.marks.moB = mo;
      student.marks.moB = mo;
    }
    else if ( indicator === "c" )
    {
      item.marks.moC = mo;
      student.marks.moC = mo;
    }

    setMarksList( items );

    const payload = {
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      form_data: {
        marks: [
          {
            "classId": student.classId,
            "indicator": indicator,
            "marks": mo
          }
        ]
      }
    };

    try
    {
      const res = await postRequest( "exam-marks-tabulation-formative-update", payload );

      if ( res.data.error === 0 )
      {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr d-flex justify-content-between align-items-center">
              <h2 className="m-0">Student List of Class: { props.classSection }</h2>
              <div className="panel-toolbar">
                {
                  tabulation?.lockTabulation === 0 && (
                    <>
                      <Button
                        type="success"
                        onClick={ async () =>
                        {

                          setSpinner( true );

                          const responseNewList = await postRequest( "exam-marks-tabulation-formative-list", {
                            // schoolCode: getSchoolData().school_code,
                            sessionCode: getSessionData().rcode,
                            classSection: props.classSection,
                            examId: props.examId,
                            subjectId: props.subjectId,
                            // userId: getUserData().tid,
                          } );

                          if ( responseNewList.data.error > 0 )
                          {
                            ErrorNotificationMsg( responseNewList.data.errmsg );
                            setSpinner( false );
                            return;
                          }
                          setTabulation( responseNewList.data.response.data );
                          setMarksList( responseNewList.data.response.data.students );

                          const checkData = responseNewList.data.response.data.students;
                          console.log( checkData );

                          let setLock = false;

                          if ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 11 || props.examId == 21 || props.examId == 31 )
                          {
                            for ( let i = 0; i < checkData.length; i++ )
                            {
                              if ( checkData[ i ].marks?.moA === "" )
                              {
                                setLock = false;
                                break;
                              } else
                              {
                                setLock = true;
                              }
                            }

                            if ( setLock === false )
                            {
                              ErrorNotificationMsg( "Please enter marks for all students." );
                              setSpinner( false );
                              return;
                            }
                          }
                          console.log( setLock );

                          if ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 12 || props.examId == 22 || props.examId == 32 )
                          {
                            for ( let i = 0; i < checkData.length; i++ )
                            {
                              if ( checkData[ i ].marks?.moB === "" )
                              {
                                setLock = false;
                                break;
                              } else
                              {
                                setLock = true;
                              }
                            }

                            if ( setLock === false )
                            {
                              ErrorNotificationMsg( "Please enter marks for all students." );
                              setSpinner( false );
                              return;
                            }
                          }

                          if ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 13 || props.examId == 23 || props.examId == 33 )
                          {
                            for ( let i = 0; i < checkData.length; i++ )
                            {
                              if ( checkData[ i ].marks?.moC === "" )
                              {
                                setLock = false;
                                break;
                              } else
                              {
                                setLock = true;
                              }
                            }

                            if ( setLock === false )
                            {
                              ErrorNotificationMsg( "Please enter marks for all students." );
                              setSpinner( false );
                              return;
                            }
                          }

                          const response = await postRequest( "exam-marks-finalize", {
                            // schoolCode: getSchoolData().school_code,
                            sessionCode: getSessionData().rcode,
                            classSection: props.classSection,
                            term: props.examId,
                            subjectId: props.subjectId,
                            // userId: getUserData().tid,
                            examType: "formative",
                          } );

                          setSpinner( false );
                          if ( response.data.error > 0 )
                          {
                            ErrorNotificationMsg( response.data.errmsg );
                          }


                          getTabulation();
                        }
                        }
                        className="btn btn-danger waves-effect waves-themed m-2"
                      >
                        Finalize
                      </Button>
                    </>
                  )
                }
              </div>
            </div>



            <div className="panel-container show">
              <div className="panel-content">
                <div className="form-row">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-danger fade show px-3 py-2">
                      Please enter [ <strong>111</strong> if Absent (AB) ] & [ <strong>222</strong> if Not Applicable (NA) ]
                    </div>
                  </div>
                </div>
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={ formRef }
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          {
                            ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 11 || props.examId == 21 || props.examId == 31 ) && <th>{ `F${ props.examId.toString().slice( 0, 1 ) }A [F.M.- ${ tabulation?.fullMarks?.fmA }]` }</th>
                          }
                          {
                            ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 12 || props.examId == 22 || props.examId == 32 ) && <th>{ `F${ props.examId.toString().slice( 0, 1 ) }B [F.M.- ${ tabulation?.fullMarks?.fmB }]` }</th>
                          }
                          {
                            ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 13 || props.examId == 23 || props.examId == 33 ) && <th>{ `F${ props.examId.toString().slice( 0, 1 ) }C [F.M.- ${ tabulation?.fullMarks?.fmC }]` }</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td><strong>{ student?.stdName }</strong></td>

                                {
                                  ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 11 || props.examId == 21 || props.examId == 31 ) &&
                                  <td>
                                    { tabulation?.lockTabulation === 1 ?
                                      <Input
                                        type="text"
                                        name="marks"
                                        //value={student?.marks}
                                        value={
                                          student.marks[ `moA` ] === '111.0' || student.marks[ `moA` ] === '222.0' || student.marks[ `moA` ] === '333.0'
                                            ? Math.floor( student.marks[ `moA` ] )
                                            : student.marks[ `moA` ]
                                        }
                                        disabled={ true }
                                      />
                                      :
                                      <Input
                                        type="text"
                                        name="marks"
                                        value={
                                          student.marks[ `moA` ] === '111.0' || student.marks[ `moA` ] === '222.0' || student.marks[ `moA` ] === '333.0'
                                            ? Math.floor( student.marks[ `moA` ] )
                                            : student.marks[ `moA` ]
                                        }
                                        // id={ `inputId${ student?.classId }_a` }

                                        onBlur={ ( event ) => checkMarks( event, student, "a" ) }
                                        onChange={ ( event ) => handleMarksChange( event, student, "a" ) }
                                      />
                                    }
                                  </td>
                                }

                                {
                                  ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 12 || props.examId == 22 || props.examId == 32 ) &&
                                  <td>
                                    { tabulation?.lockTabulation === 1 ?
                                      <Input
                                        type="text"
                                        name="marks"
                                        //value={student?.marks}
                                        value={
                                          student.marks[ `moB` ] === '111.0' || student.marks[ `moB` ] === '222.0' || student.marks[ `moB` ] === '333.0'
                                            ? Math.floor( student.marks[ `moB` ] )
                                            : student.marks[ `moB` ]
                                        }
                                        disabled={ true }
                                      />
                                      :
                                      <Input
                                        type="text"
                                        name="marks"
                                        value={
                                          student.marks[ `moB` ] === '111.0' || student.marks[ `moB` ] === '222.0' || student.marks[ `moB` ] === '333.0'
                                            ? Math.floor( student.marks[ `moB` ] )
                                            : student.marks[ `moB` ]
                                        }
                                        id={ `inputId${ student?.classId }_b` }

                                        onBlur={ ( event ) => checkMarks( event, student, "b" ) }
                                        onChange={ ( event ) => handleMarksChange( event, student, "b" ) }
                                      />
                                    }
                                  </td>
                                }

                                {
                                  ( props.examId == 1 || props.examId == 2 || props.examId == 3 || props.examId == 13 || props.examId == 23 || props.examId == 33 ) &&
                                  <td>
                                    { tabulation?.lockTabulation === 1 ?
                                      <Input
                                        type="text"
                                        name="marks"
                                        //value={student?.marks}
                                        value={
                                          student.marks[ `moC` ] === '111.0' || student.marks[ `moC` ] === '222.0' || student.marks[ `moC` ] === '333.0'
                                            ? Math.floor( student.marks[ `moC` ] )
                                            : student.marks[ `moC` ]
                                        }
                                        disabled={ true }
                                      />
                                      :
                                      <Input
                                        type="text"
                                        name="marks"
                                        value={
                                          student.marks[ `moC` ] === '111.0' || student.marks[ `moC` ] === '222.0' || student.marks[ `moC` ] === '333.0'
                                            ? Math.floor( student.marks[ `moC` ] )
                                            : student.marks[ `moC` ]
                                        }
                                        id={ `inputId${ student?.classId }_c` }

                                        onBlur={ ( event ) => checkMarks( event, student, "c" ) }
                                        onChange={ ( event ) => handleMarksChange( event, student, "c" ) }
                                      />
                                    }
                                  </td>
                                }


                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListFormative;