import React, { useCallback, useRef, useState } from "react";
import { Modal } from "antd";

import { postRequest } from "../../axios";

import { getSessionData, getUserData, getUserType } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import { useNavigate, useOutletContext } from "react-router-dom";
import Webcam from "react-webcam";

const StudentDetails = ( props ) =>
{
  const [ showModel, setShowModel ] = useState( false );
  const [ studentProfile, setStudentProfile ] = useState( null );
  const setSpinner = useOutletContext();
  const navigate = useNavigate();

  const hideModelFunction = () =>
  {
    setShowModel( false );
  };

  const showModelFunction = () =>
  {
    setShowModel( true );
    getStudentProfile();
  };

  const getStudentProfile = async () =>
  {
    setSpinner( true );
    const response = await postRequest( "get-student-personal-details", {
      student_id: props.sid,
    } );
    setStudentProfile( response.data.response );
    setSpinner( false );
  };


  const [ showPhotoModel, setShowPhotoModel ] = useState( false );
  const [ filePath, setFilePath ] = useState( null );

  const hidePhotoModelFunction = () =>
  {
    setShowPhotoModel( false );
  };

  const showPhotoModelFunction = () =>
  {
    setShowPhotoModel( true );
    getStudentProfile();
  };

  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';

  const webcamRef = useRef( null );
  const [ image, setImage ] = useState( '' );
  const [ facingModel, setFacingMode ] = useState( FACING_MODE_ENVIRONMENT );

  const videoConstraints = {
    facingModel: facingModel,
    width: 750,
    height: 400,
  };

  const handleSwitchCamera = useCallback( () =>
  {
    setFacingMode( ( prevState ) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, [] );

  const handleSubmit = useCallback( () =>
  {
    if ( image )
    {
      // Convert the base64 image to a Blob
      const byteString = atob( image.split( ',' )[ 1 ] );
      const mimeString = image.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];

      const ab = new ArrayBuffer( byteString.length );
      const ia = new Uint8Array( ab );
      for ( let i = 0; i < byteString.length; i++ )
      {
        ia[ i ] = byteString.charCodeAt( i );
      }

      const blob = new Blob( [ ab ], { type: mimeString } );
      const file = new File( [ blob ], "captured-image.png", { type: "image/png" } );

      setFilePath( file ); // Save the photo as a File object
      console.log( "File saved:", file );

      // Additional functionality: You can upload this file to a server here
    }
  }, [ image ] );

  const [ showCamera, setShowCamera ] = useState( false );

  const startCamera = async () =>
  {
    try
    {
      setShowCamera( true );
    } catch ( error )
    {
      console.error( 'Error accessing the camera:', error );
    }
  };

  const stopCamera = () =>
  {
    setShowCamera( false );
  };

  const capture = useCallback( () =>
  {
    if ( webcamRef.current )
    {
      const imageSrc = webcamRef.current.getScreenshot();
      if ( imageSrc )
      {
        setImage( imageSrc );
      }
    }
  }, [] );


  return (
    <>
      <button
        className="btn btn-sm btn-outline-info"
        onClick={ () => showModelFunction() }
      >
        VIEW PROFILE
      </button>

      <Modal
        title="Students' Profile"
        open={ showModel }
        onOk={ hideModelFunction }
        okText="Close"
        okType="secondary"
        onCancel={ hideModelFunction }
        cancelButtonProps={ { style: { display: "none" } } }
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-2">

            {/* <div className="card border m-auto m-lg-0">
              <img
                src={ studentProfile?.image_url }
                className="img-thumbnail"
                alt="Profile Photo"
                onError={ ( e ) =>
                {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                } }
              />
            </div> */}

            <div onClick={ () =>
            {
              if ( ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) )
                navigate( `/staff-student-profile-edit/${ props.sid }/student` )
            }
            } className="card border m-auto m-lg-0 align-items-center" style={ { maxWidth: "100%" } }>
              <span className="label label-primary text-center">STUDENT'S PHOTO</span>
              <img
                id={ `stdImage${ studentProfile?.id }` } // Dynamic ID
                src={ studentProfile?.image_url }
                className="img-thumbnail"
                alt="Profile Photo"
                onError={ ( e ) =>
                {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                } }
              />

              {
                ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) && (
                  <>
                    <div className="card-body p-1 d-flex justify-content-between gap-3">
                      <button
                        type="button"
                        className="btn btn-icon btn-sm btn-success waves-effect waves-themed mx-2"
                        onClick={ () =>
                        {
                          startCamera();
                          showPhotoModelFunction();
                        } }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Upload From File"
                      >
                        <i className="fal fa-upload"></i>
                      </button>
                      {/* <button
                  type="button"
                  className="btn btn-icon btn-sm btn-primary waves-effect waves-themed mx-2"
                  // onClick={ () => UploadPhoto( studentProfile?.id, "cam", "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Upload From Webcam"
                >
                  <i className="fal fa-camera"></i>
                </button> */}
                      {/* <button
                  id={ `delBtn${ studentProfile?.id }` }
                  type="button"
                  className="btn btn-icon btn-sm btn-danger waves-effect waves-themed mx-2"
                  // onClick={ () => RemovePhoto( studentProfile?.id, "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Remove Photo"
                >
                  <i className="fal fa-times"></i>
                </button> */}
                    </div>
                  </>
                )
              }

            </div>

            <div onClick={ () =>
            {
              if ( ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) )
                navigate( `/staff-student-profile-edit/${ props.sid }/father` )
            }
            } className="card border m-auto m-lg-0 align-items-center" style={ { maxWidth: "100%" } }>
              <span className="label label-primary text-center">FATHER'S PHOTO</span>
              <img
                id={ `stdImage${ studentProfile?.id }` } // Dynamic ID
                src={ studentProfile?.father_image_url }
                className="img-thumbnail"
                alt="Profile Photo"
                onError={ ( e ) =>
                {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                } }
              />


              {
                ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) && (
                  <>
                    <div className="card-body p-1 d-flex justify-content-between gap-3">
                      <button
                        type="button"
                        className="btn btn-icon btn-sm btn-success waves-effect waves-themed mx-2"
                        onClick={ () => showPhotoModelFunction() }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Upload From File"
                      >
                        <i className="fal fa-upload"></i>
                      </button>
                      {/* <button
                  type="button"
                  className="btn btn-icon btn-sm btn-primary waves-effect waves-themed mx-2"
                  // onClick={ () => UploadPhoto( studentProfile?.id, "cam", "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Upload From Webcam"
                >
                  <i className="fal fa-camera"></i>
                </button> */}
                      {/* <button
                  id={ `delBtn${ studentProfile?.id }` }
                  type="button"
                  className="btn btn-icon btn-sm btn-danger waves-effect waves-themed mx-2"
                  // onClick={ () => RemovePhoto( studentProfile?.id, "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Remove Photo"
                >
                  <i className="fal fa-times"></i>
                </button> */}
                    </div>
                  </>
                )
              }


            </div>

            <div onClick={ () =>
            {
              if ( ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) )
                navigate( `/staff-student-profile-edit/${ props.sid }/mother` )
            }
            } className="card border m-auto m-lg-0 align-items-center" style={ { maxWidth: "100%" } }>
              <span className="label label-primary text-center">MOTHER'S PHOTO</span>
              <img
                id={ `stdImage${ studentProfile?.id }` } // Dynamic ID
                src={ studentProfile?.mother_image_name }
                className="img-thumbnail"
                alt="Profile Photo"
                onError={ ( e ) =>
                {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                } }
              />


              {
                ( getUserType() == "student" && studentProfile?.can_upload_image == 1 ) || ( getUserData().staff_can_upload_std_photo == 1 && getUserType() == "staff" ) && (
                  <>
                    <div className="card-body p-1 d-flex justify-content-between gap-3">
                      <button
                        type="button"
                        className="btn btn-icon btn-sm btn-success waves-effect waves-themed mx-2"
                        onClick={ () => showPhotoModelFunction() }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Upload From File"
                      >
                        <i className="fal fa-upload"></i>
                      </button>
                      {/* <button
                  type="button"
                  className="btn btn-icon btn-sm btn-primary waves-effect waves-themed mx-2"
                  // onClick={ () => UploadPhoto( studentProfile?.id, "cam", "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Upload From Webcam"
                >
                  <i className="fal fa-camera"></i>
                </button> */}
                      {/* <button
                  id={ `delBtn${ studentProfile?.id }` }
                  type="button"
                  className="btn btn-icon btn-sm btn-danger waves-effect waves-themed mx-2"
                  // onClick={ () => RemovePhoto( studentProfile?.id, "0" ) }
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Remove Photo"
                >
                  <i className="fal fa-times"></i>
                </button> */}
                    </div>
                  </>
                )
              }


            </div>


            <table className="table table-sm table-bordered table-hover table-striped w-100 mt-2">
              <thead className="thead-themed">
                <tr><th colSpan={ 2 }>PRESENT CLASS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Session</td><td>{ studentProfile?.student_class.std_session }</td>
                </tr>
                <tr>
                  <td>Class</td><td>{ studentProfile?.student_class.std_class }</td>
                </tr>
                <tr>
                  <td>Section</td><td>{ studentProfile?.student_class.std_section }</td>
                </tr>
                <tr>
                  <td>Roll</td><td>{ studentProfile?.student_class.std_roll }</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5">
            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={ 2 } className="text-center">PERSONAL DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Student's ID</td><td><strong>{ studentProfile?.std_id }</strong></td>
                </tr>
                { studentProfile?.std_bs_code &&
                  <tr>
                    <td>Banglar Siksha ID</td><td><strong>{ studentProfile?.std_bs_code }</strong></td>
                  </tr>
                }
                <tr>
                  <td>Student's Name </td><td><strong>{ studentProfile?.std_name }</strong></td>
                </tr>
                <tr>
                  <td>Date of Birth</td><td>{ studentProfile?.std_birth_date }</td>
                </tr>
                <tr>
                  <td>Gender </td><td>{ studentProfile?.std_gender_text }</td>
                </tr>
                <tr>
                  <td>Caste</td><td>{ studentProfile?.std_caste_text }</td>
                </tr>
                <tr>
                  <td>Blood Group</td><td>{ studentProfile?.std_blood_group_text }</td>
                </tr>
                <tr>
                  <td>Second Language</td><td>{ studentProfile?.second_lang }</td>
                </tr>
                <tr>
                  <td>Third Language</td><td>{ studentProfile?.third_lang }</td>
                </tr>
                <tr>
                  <td>Religion</td><td>{ studentProfile?.std_religion_text }</td>
                </tr>
                <tr>
                  <td>Aadhaar Card No.</td><td>{ studentProfile?.std_aadhaar }</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5">
            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={ 2 } className="text-center">PARENT DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Father's Name</td><td>{ studentProfile?.std_father_name }</td>
                </tr>
                <tr>
                  <td>Father's Contact No.</td><td>{ studentProfile?.std_father_aadhaar }</td>
                </tr>
                <tr>
                  <td>Mother's Name</td><td>{ studentProfile?.std_mother_name }</td>
                </tr>
                <tr>
                  <td>Mother's Contact No.</td><td>{ studentProfile?.std_mother_aadhaar }</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={ 2 } className="text-center">CONTACT DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Address</td>
                  <td>
                    <span className="d-block">Village - { studentProfile?.std_village }</span>
                    <span className="d-block">P.O. - { studentProfile?.std_po }</span>
                    <span className="d-block">P.S. - { studentProfile?.std_ps }</span>
                    <span className="d-block">Dist. - { studentProfile?.std_district_text }</span>
                    <span className="d-block">PIN - { studentProfile?.std_pin }</span>
                  </td>
                </tr>
                <tr>
                  <td>Primary Mobile No.</td><td>{ studentProfile?.std_contact }</td>
                </tr>
                <tr>
                  <td>Whatsapp</td><td>{ studentProfile?.std_contact_whatsapp }</td>
                </tr>
              </tbody>
            </table>

            { studentProfile?.bank_details && studentProfile?.bank_details?.account_no &&
              <table className="table table-sm table-bordered table-hover table-striped w-100">
                <thead className="thead-themed">
                  <tr><th colSpan={ 2 } className="text-center">BANK DETAILS</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Bank Name</td><td>{ studentProfile?.bank_details.bank_name }</td>
                  </tr>
                  <tr>
                    <td>Bank Branch</td><td>{ studentProfile?.bank_details.branch_name }</td>
                  </tr>
                  <tr>
                    <td>Account No.</td><td>{ studentProfile?.bank_details.account_no }</td>
                  </tr>
                  <tr>
                    <td>IFS Code</td><td>{ studentProfile?.bank_details.ifsc_code }</td>
                  </tr>
                </tbody>
              </table>
            }
          </div>
        </div>
      </Modal>

      <Modal
        title="Students' Profile"
        open={ showPhotoModel }
        onOk={ hidePhotoModelFunction }
        okText="Close"
        okType="secondary"
        onCancel={ hidePhotoModelFunction }
        cancelButtonProps={ { style: { display: "none" } } }
      >
        <div className="row">
          {/* Left Side: Camera and Image Preview */ }
          <div className="col-sm-12 col-md-12 col-lg-5">
            <div className="card shadow-sm p-3">
              <h5 className="text-center mb-3">Camera & Photo Capture</h5>

              {/* Camera Modal */ }
              { showCamera && (
                <div className="camera-container text-center">
                  <div className="border rounded p-3 bg-light">
                    { image === "" ? (
                      <Webcam
                        key={ showCamera } // Force re-render
                        audio={ false }
                        ref={ webcamRef }
                        screenshotFormat="image/jpeg"
                        videoConstraints={ videoConstraints }
                        screenshotQuality={ 1 }
                        className="rounded border shadow-sm"
                        style={ { width: "100%", maxWidth: "400px", height: "auto" } }
                      />
                    ) : (
                      <img
                        src={ image }
                        alt="Captured"
                        className="rounded border shadow-sm"
                        style={ { width: "100%", maxWidth: "400px", height: "auto" } }
                      />
                    ) }
                  </div>

                  <div className="d-flex flex-wrap justify-content-center gap-2 mt-3">
                    { image === "" ? (
                      <button type="button" className="btn btn-info" onClick={ capture }>
                        Capture Photo
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={ () => setImage( "" ) }
                      >
                        Retake Photo
                      </button>
                    ) }

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ handleSwitchCamera }
                    >
                      Switch Camera
                    </button>

                    <button type="button" className="btn btn-success" onClick={ handleSubmit }>
                      Submit Photo
                    </button>

                    <button type="button" className="btn btn-danger" onClick={ stopCamera }>
                      Stop Camera
                    </button>
                  </div>
                </div>
              ) }

              {/* Preview Uploaded or Captured Image */ }
              { filePath && (
                <div className="mt-4">
                  <h6 className="text-center">Preview:</h6>
                  <div className="text-center">
                    { filePath instanceof File ? (
                      <div>
                        <img
                          src={ URL.createObjectURL( filePath ) }
                          alt="Captured or Uploaded"
                          className="rounded border shadow-sm"
                          style={ { maxWidth: "300px", height: "auto" } }
                        />
                        <p className="mt-2 text-muted">{ filePath.name }</p>
                      </div>
                    ) : (
                      <p className="text-muted">{ filePath.name }</p>
                    ) }
                  </div>
                </div>
              ) }
            </div>
          </div>
        </div>
      </Modal>


    </>
  );
};

export default StudentDetails;
