import React, { useState, useEffect } from "react";
import
{
  Button
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";

import { useOutletContext, useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { ImageCropper } from "../../utils/cropUtils";



const ImageUploadingButton = ( { value, onChange, ...props } ) =>
{
  return (
    <ImageUploading value={ value } onChange={ onChange }>
      { ( { onImageUpload, onImageUpdate } ) => (


        <Button
          type="secondary"
          onClick={ value ? onImageUpload : () => onImageUpdate( 0 ) }
          { ...props }

          className="btn btn-primary ml-auto waves-effect waves-themed"
        >
          Select Image
        </Button>
      ) }
    </ImageUploading>
  );
};


const StaffStudentProfilePhotoEdit = () =>
{


  const { student_id, typeImage } = useParams();
  const TypeImage = typeImage.charAt( 0 ).toUpperCase() + typeImage.slice( 1 );

  const [ studentProfile, setStudentProfile ] = useState( null );
  const setSpinner = useOutletContext();

  const [ image, setImage ] = useState( [] );
  const [ croppedImage, setCroppedImage ] = useState( null );
  const [ dialogOpen, setDialogOpen ] = useState( false );


  const getStudentProfile = async () =>
  {
    setSpinner( true );
    const response = await postRequest( "get-student-personal-details", {
      student_id: student_id,
    } );
    setStudentProfile( response.data.response );
    setSpinner( false );
  };


  useEffect( () =>
  {
    getStudentProfile();
  }, [] );




  const getImageSize = ( imageSrc ) =>
  {
    return new Promise( ( resolve, reject ) =>
    {
      const img = new Image();
      img.onload = () => resolve( { width: img.width, height: img.height } );
      img.onerror = ( err ) => reject( err );
      img.src = imageSrc;
    } );
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Students' Profile"
        pageIcon={ <i className='subheader-icon fal fa-user'></i> }
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>{ `${ TypeImage }'s Profile Edit` }</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row">
                  {/* Left Side: Camera and Image Preview */ }
                  <div className="col-sm-12 col-md-12 col-lg-5">
                    <div className="card shadow-sm p-3">
                      <h5 className="text-center mb-3">Photo Capture</h5>


                      {
                        croppedImage
                          ? <>
                            { croppedImage && (
                              <div style={ {
                                position: "relative",
                                display: "inline-block",
                                width: "130px", // Adjusted for padding
                                height: "130px"
                              } }>
                                <button
                                  onClick={ () =>
                                  {
                                    setCroppedImage( null );
                                  } } // Replace with actual remove function
                                  style={ {
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer"
                                  } }
                                >
                                  ✖
                                </button>
                                <img
                                  key={ 1 }
                                  src={ croppedImage }
                                  alt={ `Cropped ${ 1 }` }
                                  style={ {
                                    width: "120px",
                                    height: "120px",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                    border: "1px solid #ddd",
                                    padding: "5px"
                                  } }
                                />
                              </div>

                            ) }
                          </>
                          : studentProfile
                            ? <>
                              <div style={ {
                                position: "relative",
                                display: "inline-block",
                                width: "130px", // Adjusted for padding
                                height: "130px"
                              } }>
                                <img
                                  key={ 1 }
                                  src={
                                    typeImage == "student"
                                      ? studentProfile?.image_url
                                      : typeImage == "father"
                                        ? studentProfile?.father_image_url
                                        : typeImage == "mother"
                                          ? studentProfile?.mother_image_url
                                          : ""
                                  }
                                  alt={ `Cropped ${ 1 }` }
                                  style={ {
                                    width: "120px",
                                    height: "120px",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                    border: "1px solid #ddd",
                                    padding: "5px"
                                  } }
                                />
                              </div>
                            </>
                            : <></>
                      }


                      {
                        croppedImage
                          ? <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={
                                async () =>
                                {



                                  let myPhoto = croppedImage.replace( "data:image/jpeg;base64,", "" )


                                  setSpinner( true );
                                  const response = await postRequest( "upload-student-image", {
                                    "student_id": studentProfile?.id,
                                    "stu_img_id": studentProfile?.image_id,
                                    "type": typeImage,
                                    "image": {
                                      "ext": "jpg",
                                      "file": myPhoto
                                    }
                                  } );

                                  console.log( response );

                                  setCroppedImage( null );
                                  setSpinner( false );
                                }
                              }
                            >
                              Update Image
                            </button>
                          </>
                          : studentProfile
                            ? <>
                              <ImageUploadingButton
                                value={ image }
                                onChange={ ( newImage ) =>
                                {
                                  setDialogOpen( true );
                                  setImage( newImage );
                                } }
                              />
                            </>
                            : <></>
                      }









                    </div>
                  </div>

                  <ImageCropper
                    setSpinner={ setSpinner }
                    open={ dialogOpen }
                    image={ image.length > 0 && image[ 0 ].dataURL }
                    aspect={ 1 } // ✅ Auto aspect ratio
                    onComplete={ ( imagePromisse ) =>
                    {
                      imagePromisse.then( ( image ) =>
                      {
                        setCroppedImage( image ); // ✅ Adds new cropped image
                        setDialogOpen( false );
                      } );
                    } }
                    containerStyle={ {
                      position: "relative",
                      width: "100%",
                      height: 300,
                      background: "#333"
                    } }
                    onClose={ () => setDialogOpen( false ) } // ✅ Close modal when clicking the cross
                  />


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default StaffStudentProfilePhotoEdit;
