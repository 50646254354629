import React, { useState } from "react";

import 'antd/dist/reset.css';
import { Outlet } from "react-router-dom";
import { Spin } from "antd";

const MobileLayout = ( props ) =>
{

  const [ spinner, setSpinner ] = useState( true );

  let spinStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    verticalAlign: 'middle'
  };

  return (
    <div className="page-wrapper mod-nav-link">
      <div className="page-inner">
        <div className="page-content-wrapper">

          <Spin spinning={ spinner } tip="Loading..." size="large" style={ spinStyle }>
            <div className="m-2">
              <Outlet context={ setSpinner } />
            </div>

          </Spin>

        </div>
      </div>
    </div>
  );
};

export default MobileLayout;