import React, { useState } from "react";
import parse from 'html-react-parser';
import { Modal } from "antd";

import { postRequest } from "../../axios";

import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const ReportCard = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [reportCard, setReportCard] = useState(null);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const getReportCard = async () => {
    setSpinner(true);
    const response = await postRequest("exam-get-report-card", {
      class_id: props.classId,
      exam_id: props.examId
    });
    setSpinner(false);
    if (response.data.error === 0) {
      setShowModel(true);
      setReportCard(response.data.response.reportCard);
    } else {
      setShowModel(false);
      ErrorNotificationMsg(response.data.errmsg);
    }
  };

  return (
    <>
      <button
        className="btn btn-info"
        onClick={() => getReportCard()}
      >
        SHOW REPORT CARD
      </button>

      <Modal
        title="Report Card"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="table-responsive">
          {reportCard && parse(reportCard)}
        </div>
      </Modal>
    </>
  );
};

export default ReportCard;