import React, { useState, useEffect } from "react";
import axios from "axios";

import Config from "../../../Config";
import
{
    ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { useParams } from "react-router-dom";

const LoginMobileDashboard = ( props ) =>
{
    const { dbToken, resToken, redirect } = useParams();

    console.log( redirect );

    useEffect( () =>
    {
        storelocalStorage();
    }, [] )


    const storelocalStorage = async () =>
    {

        const userDetailResponse = await axios.post(
            Config.API_URL + "login-user-info",
            {},
            {
                headers: {
                    DBAuth: dbToken,
                    Authorization: `Bearer ${ resToken }`
                },
            }
        );

        if ( userDetailResponse.data.error !== 0 )
        {
            ErrorNotificationMsg( userDetailResponse.data.errmsg );
            localStorage.clear();
        }

        let userData = {
            staff_can_upload_std_photo: userDetailResponse.data.response.school_information.staff_can_upload_std_photo,
            name: userDetailResponse.data.response.name,
            mobile: userDetailResponse.data.response.mobile,
            image_url: userDetailResponse.data.response.image_url,
            unique_id: userDetailResponse.data.response.unique_id,
            tid: userDetailResponse.data.response.id,
            referenceId: userDetailResponse.data.response.reference_id,
            classId: userDetailResponse.data.response?.classId,
            stdClass: userDetailResponse.data.response?.stdClass,
            stdSection: userDetailResponse.data.response?.stdSection,
            stdRoll: userDetailResponse.data.response?.stdRoll,
            staffType: userDetailResponse.data.response?.type,
            appPaidStatus: userDetailResponse.data.response?.appPaidStatus,
            appPaidDetails: userDetailResponse.data.response?.appPaidDetails,
        };

        let schoolData = {
            school_code: userDetailResponse.data.response.school_information.school_code,
            sch_img: userDetailResponse.data.response.school_image.school_logo,
            sch_name: userDetailResponse.data.response.school_information.sch_name,
            school_type: userDetailResponse.data.response.school_information.school_type,
            contact: userDetailResponse.data.response.school_information.sch_contact_land,
            onlinePayment: userDetailResponse.data.response.school_information.online_payment,
            paymentGatewayList: userDetailResponse.data.response.school_information.payment_gateway_list,
            liveClassVendor: userDetailResponse.data.response?.liveClassVendor,
            googleAuthOne: userDetailResponse.data.response.school_information.google_auth_one,
            googleAuthTwo: userDetailResponse.data.response.school_information.google_auth_two,
            gocTwoStaffIds: userDetailResponse.data.response.school_information.goc_two_staff_ids,
            activityExam: userDetailResponse.data.response?.activity_exam,
            attendanceType: userDetailResponse.data.response?.attendanceType,
            appPaymentDetails: userDetailResponse.data.response.school_information.app_payment_details,
        };

        localStorage.clear();
        localStorage.setItem( "dbtoken", dbToken );
        localStorage.setItem( "restoken", resToken );

        let userType = 'staff';
        if ( userDetailResponse.data.response?.privilege == 1 )
        {
            userType = 'student';
        }
        localStorage.setItem( "userType", userType );

        localStorage.setItem(
            "session_data",
            JSON.stringify( userDetailResponse.data.response.sessionData )
        );

        localStorage.setItem( "schoolData", JSON.stringify( schoolData ) );
        localStorage.setItem(
            "school_menu",
            userDetailResponse.data.response.school_information.school_menu
        );

        localStorage.setItem( "userData", JSON.stringify( userData ) );
        localStorage.setItem(
            "upload_img_limit",
            userDetailResponse.data.response.hw_submission_image_upload_limit
        );

        // SuccessNotificationMsg("Success", "Successfully logged in!");

        window.location.href = "/mobile-dashboard";

    };


    return (
        <>
            <span>Loading...</span>
        </>
    );
};

export default LoginMobileDashboard;
