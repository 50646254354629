import React, { useState, useEffect } from "react";
import
  {
    Row,
    Col,
    Select,
    Form,
    Button,
    Space,
  } from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import
  {
    getSessionData,
    getUserData
  } from "../../utils/Helpers";
import ReportStudentList from "./ReportStudentList";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const AcademicReport = () =>
{
  const [ state, setState ] = useState( {
    class_section: null,
    exam_id: null,
  } );
  const [ classList, setClassList ] = useState( [] );
  const [ examList, setExamList ] = useState( [] );
  const [ showList, setShowList ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getClassList();
  }, [] );

  const handleSelectChange = ( field, value ) =>
  {
    setState( { ...state, [ field ]: value } );
    setShowList( false );
  };

  const getClassList = async () =>
  {
    setSpinner( true );
    const classRes = await postRequest( "get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    } );

    let classArr = classRes.data.response.as_class_teacher;
    setClassList( classArr );

    const examRes = await postRequest( "exam-get-report-card-exam-list" );
    setExamList( examRes.data.response.examList );
    setSpinner( false );
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Academic Report"
        pageIcon={ <i className="subheader-icon fal fa-clipboard-list-check"></i> }
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Academic Report</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={ [ 15 ] } className="mb-3">
                    <Col xs={ 24 } sm={ 8 } lg={ 8 }>
                      <Form.Item
                        name="class_section"
                        label="Class"
                        rules={ [
                          {
                            required: true,
                            message: "Please select class!",
                          },
                        ] }
                      >
                        <Select placeholder="Select Class"
                          onChange={ ( value ) =>
                            handleSelectChange( "class_section", value )
                          }
                        >
                          { !!classList &&
                            classList.map( ( s ) => (
                              <Option key={ s } value={ s }>
                                { s }
                              </Option>
                            ) ) }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={ 24 } sm={ 8 } lg={ 8 }>
                      <Form.Item
                        name="exam_id"
                        label="Examination"
                        rules={ [
                          {
                            required: true,
                            message: "Please select exam!",
                          },
                        ] }
                      >
                        <Select
                          allowClear
                          placeholder="Select Exam"
                          onChange={ ( value ) =>
                            handleSelectChange( "exam_id", value )
                          }
                        >
                          { !!examList &&
                            examList.map( ( s ) => (
                              <Option
                                key={ s.examId }
                                value={ s.examId }
                              >
                                { s.examName }
                              </Option>
                            ) ) }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={ () =>
                        setShowList( true )
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Student List
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { showList && (
        <ReportStudentList
          classSection={ state.class_section }
          examId={ state.exam_id }
        />
      ) }
    </div>
  );
};

export default AcademicReport;
