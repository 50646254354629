import React, { useEffect, useRef, useState } from "react";
import
  {
    Row,
    Col,
    Select,
    Form,
    Button,
    Space,
  } from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import
  {
    getSessionData,
    getUserData,
  } from "../../utils/Helpers";
import { Option } from "antd/lib/mentions";
import StudentListResultRemarks from "./StudentListResultRemarks";
import { useOutletContext } from "react-router-dom";

const ResultRemarks = () =>
{
  const formRef = useRef();
  const [ state, setState ] = useState( {
    classSection: null,
    examId: null,
  } );
  const [ classSectionList, setClassSectionList ] = useState( [] );
  const [ examList, setExamList ] = useState( [] );
  const [ showList, setShowList ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getClassSectionList();
  }, [] );

  const getClassSectionList = async () =>
  {
    setSpinner( true );
    const classRes = await postRequest( "get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    } );

    let classArr = classRes.data.response.as_class_teacher;
    setClassSectionList( classArr );

    const examRes = await postRequest( "exam-get-report-card-exam-list" );
    setExamList( examRes.data.response.examList );
    setSpinner( false );
  };

  const handleSelectChange = ( field, value ) =>
  {
    setState( { ...state, [ field ]: value } );
    setShowList( false );
  };

  const handleShowList = ( value ) =>
  {
    setShowList( value );
  }

  return (
    <div id="content">
      <PageHeader
        pageTitle="Result Remarks"
        pageIcon={ <i className="subheader-icon fal fa-clipboard-list-check"></i> }
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>ResultRemarks</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={ formRef }
                >
                  <Row gutter={ [ 15 ] } className="mb-3">
                    <Col xs={ 24 } sm={ 8 } lg={ 8 }>
                      <Form.Item
                        name="classSection"
                        label="Class"
                        rules={ [
                          {
                            required: true,
                            message: "Please select class!",
                          },
                        ] }
                      >
                        <Select placeholder="Select Class"
                          onChange={ ( value ) =>
                            handleSelectChange( "classSection", value )
                          }
                        >
                          { !!classSectionList &&
                            classSectionList.map( ( s ) => (
                              <Option key={ s } value={ s }>
                                { s }
                              </Option>
                            ) ) }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={ 24 } sm={ 8 } lg={ 8 }>
                      <Form.Item
                        name="examId"
                        label="Examination"
                        rules={ [
                          {
                            required: true,
                            message: "Please select exam!",
                          },
                        ] }
                      >
                        <Select
                          allowClear
                          placeholder="Select Examination"
                          onChange={ ( value ) =>
                            handleSelectChange( "examId", value )
                          }
                        >
                          { !!examList &&
                            examList.map( ( s ) => (
                              <Option key={ s.examId } value={ s.examId }>
                                { s.examName }
                              </Option>
                            ) ) }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={ () =>
                        setShowList( true )
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Student List
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { showList && (
        <StudentListResultRemarks
          classSection={ state.classSection }
          examId={ state.examId }
          handleShowList={ ( value ) =>
          {
            handleShowList( value )
          } }
        />
      ) }
    </div>
  );
};

export default ResultRemarks;