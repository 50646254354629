import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import
{
  getSchoolData,
  getUserData,
  getUserType,
  logout,
} from "../../utils/Helpers";
import Sidebar from "../common/Sidebar";
import SidebarStudent from "../common/SidebarStudent";
import ChangePassword from "../authentication/ChangePassword";

import 'antd/dist/reset.css';




const AdminLayout = () =>
{
  const [ isSidebarOpen, setSidebarOpen ] = useState( true );
  const toggleSidebar = () =>
  {
    if ( window.innerWidth > 860 && isSidebarOpen )
    {
      return true;
    }
    setSidebarOpen( !isSidebarOpen );
  };

  useEffect( () =>
  {
    toggleSidebar();
  }, [] );


  const [ width, setWidth ] = useState( window.innerWidth );

  useEffect( () =>
  {
    const handleResize = () =>
    {
      setWidth( window.innerWidth );
    };

    window.addEventListener( "resize", handleResize );

    return () =>
    {
      window.removeEventListener( "resize", handleResize );
    };
  }, [] );




  return (
    <div className="page-wrapper mod-nav-link">
      <div className="page-inner">
        { getUserType() === "staff" ? (
          <Sidebar
            isSidebarOpen={ isSidebarOpen }
            toggleSidebar={ toggleSidebar }
          />
        ) : (
          <SidebarStudent
            isSidebarOpen={ isSidebarOpen }
            toggleSidebar={ toggleSidebar }
          />
        ) }



        <div className="page-content-wrapper">
          <header className="page-header">
            { width < 860 ?
              <div className="hidden-lg-up">
                <a href="#" onClick={ toggleSidebar } className="header-btn btn waves-effect waves-themed" data-action="toggle" data-class="mobile-nav-on">
                  <i className="ni ni-menu"></i>
                </a>
              </div>
              :
              <div className="hidden-md-down position-relative">
                <a href="#" onClick={ toggleSidebar } className="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden" title="Hide Navigation">
                  <i className="ni ni-menu"></i>
                </a>
              </div>
            }

            <div className="ml-auto d-flex">
              <div>
                <a
                  href="#"
                  data-toggle="dropdown"
                  title={ getUserData()?.name }
                  className="header-icon d-flex align-items-center justify-content-center ml-2 "
                >
                  <img
                    src={ getUserData()?.image_url }
                    className="profile-image rounded-circle"
                    alt={ getUserData()?.name }
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                  <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                    <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                      <span className="mr-2">
                        <img
                          src={ getUserData()?.image_url }
                          className="rounded-circle profile-image"
                          alt={ getUserData()?.name }
                        />
                      </span>
                      <div className="info-card-text">
                        <div className="fs-lg break-text">
                          { getUserData()?.name }{ " " }
                        </div>
                        <span className="break-text opacity-80">
                          { getSchoolData()?.sch_name }
                        </span>
                      </div>
                    </div>
                  </div>

                  { localStorage.loggedInFrom === "androidApp" ? (
                    <>
                      <span className="dropdown-item fw-500 pt-3 pb-3">
                        Unique ID: { getUserData()?.unique_id }
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="dropdown-divider m-0"></div>
                      <ChangePassword />
                      <div className="dropdown-divider m-0"></div>
                      <span
                        onClick={ logout }
                        className="dropdown-item fw-500 pt-3 pb-3"
                      >
                        <span>Logout</span>
                        <span className="float-right fw-n">
                          Unique ID: { getUserData()?.unique_id }
                        </span>
                      </span>
                    </> )
                  }
                </div>
              </div>
            </div>
          </header>

          <main id="js-page-content" role="main" class="page-content">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;